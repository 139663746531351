<script>
export default {
	props: [],
	data() {
		return {
			isLoading: false,
			accountData: {},
			windowData: {
				show: false,
				section: 0,
			},

			debt: {
				title: '',
				amount: '',
				meta: {
					dueMode: 1,
					dues: 1,
					amountPerDue: 1,

					targetMode: 1,
					target: '',
				}
			},
			accounts: [],

			errors: {},
		}
	},
	methods: {
		fetchAccount() {
			axios.post(`${this.$storage.API_URL}/api/account/${this.$route.params.accountId}`, this.account, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.accountData = res.data.account;
				this.componentLoaded = true;
			});
		},
		fetchAccounts() {
			axios.post(`${this.$storage.API_URL}/api/account`, {}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.accounts = res.data.accounts;
			})
		},

		toggleWindowStatus(number) {
			this.windowData.section = number;

			this.windowData.show = !this.windowData.show;
			setTimeout(() => {
				if (!this.windowData.show)
					this.resetWindowStatus();
			}, 300)
		},

		resetWindowStatus() {

		},

		changeDue(number) {
			this.debt.meta.dues = Math.max(this.debt.meta.dues + number, 1);
		},
		selectAccount(option) {
			this.debt.meta.target = option._id;
		},

		registerDebt() {
			if (!this.isLoading) {
				let {title, amount, meta} = this.debt;
				this.errors = {};

				amount = parseFloat(amount);
				this.errors.debtTitle = !title.trim() ? 'El título es obligatorio' : undefined;
				this.errors.debtAmount = isNaN(amount) || amount <= 0 || this.debt.amount.includes(',') ? 'La cantidad debe ser un número' : undefined;

				if (meta.dueMode == 2)
					this.errors.debtAmountPerDue = !meta.amountPerDue.trim() ? 'La cantidad es obligatoria' : undefined;

				this.errors.debtTarget = !meta.target.trim() ? 'El objetivo es obligatorio' : undefined;


				if (!Object.values(this.errors).some(error => error !== undefined)) {
					this.isLoading = true;
					axios.post(`${this.$storage.API_URL}/api/account/${this.$route.params.accountId}/debt`, {debt: this.debt}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
						this.isLoading = false;
						this.fetchAccount();

						this.windowData.show = false;
						this.debt = {
							title: '',
							amount: '',
							meta: {
								dueMode: 1,
								dues: 1,
								amountPerDue: 1,

								targetMode: 1,
								target: '',
							}
						};

						Swal.fire({
							title: '¡Genial!',
							icon: 'success',
							html: `Tu deuda ha sido registrada correctamente`,
							confirmButtonText: '¡Estupendo!',
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						})
					});
				}
			}
		},
		deleteDebt(debtData) {

			Swal.fire({
				title: '¿Estás seguro?',
				icon: 'info',
				html: `Este movimiento no podrá deshacerse y las cuotas ya abonadas deberán ser eliminadas manualmente de tu cuenta`,
				confirmButtonText: '¡Sí, lo estoy!',
				showCancelButton: true,
				cancelButtonText: 'Mejor no',
				customClass: this.$storage.CUSTOM_CLASS_SWAL
			}).then((response) => {
				axios.delete(`${this.$storage.API_URL}/api/account/${this.$route.params.accountId}/debt/${debtData._id}`, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}
				).then((res) => {
					this.fetchAccount();

				});
			})

		}
	},
	computed: {
		debts() {
			return this.accountData.debts;
		},

		paymentPlanPerDues() {
			if (isNaN(this.debt.amount) || isNaN(this.debt.meta.amountPerDue)) return {fullDues: 0, finalDue: 0};
			const fullDues = Math.floor(this.debt.amount / this.debt.meta.amountPerDue);
			const remainingAmount = this.debt.amount - (fullDues * this.debt.meta.amountPerDue);

			return {
				fullDues: fullDues,
				finalDue: remainingAmount
			};
		},
		accountsFiltered() {
			return this.accounts.filter((account) => account._id !== this.$route.params.accountId);
		},

		outstandingDebts() {
			if (!this.accountData.debts) return [];
			return this.accountData.debts.filter(item => item.isFinished === false);
		},
		finishedDebts() {
			if (!this.accountData.debts) return [];
			return this.accountData.debts.filter(item => item.isFinished === true);
		}
	},
	mounted() {
		this.fetchAccount();
		this.fetchAccounts();
	}
}
</script>

<template>
	<div class="content">
		<div class="window-container" v-bind:class="{opened: windowData.show}">
			<div class="background" v-on:click="toggleWindowStatus(1)"></div>
			<div class="window">
				<div class="window-content" key="0" v-bind:class="{show: windowData.section === 0}">
					<h3 data-size="20">
						Registrar
						<span data-color="naranja">deuda</span>
					</h3>
					<div class="form-group mt-20" v-bind:class="{'has-error': errors.debtTitle}">
						<label>Título</label>
						<div class="input-group">
							<input type="text" v-on:focus="delete errors.debtTitle" v-model="debt.title">
						</div>
					</div>


					<div class="form-group mt-10" v-bind:class="{'has-error': errors.debtAmount}">
						<label>Cantidad total</label>
						<div class="input-group">
							<input type="text" v-on:focus="delete errors.debtAmount" v-model="debt.amount">
						</div>
					</div>

					<div class="d-flex mt-30" data-align-items="center">
						<p data-size="12" class="mr-10 no-wrap" data-weight="300" data-opacity="4">Configuración de
							pago</p>
						<div class="separator-horizontal"/>
					</div>

					<div class="form-group mt-10">
						<label>Distribución</label>
						<div class="input-group">
							<select v-model="debt.meta.dueMode">
								<option value="1">Por cuotas</option>
								<option value="2">Elegir importe</option>
							</select>
						</div>
					</div>

					<div class="separator-horizontal my-20"></div>

					<div v-if="debt.meta.dueMode == 1">
						<div class="d-flex" data-gap-h="10" data-align-items="center">
							<div class="square centered w-20-px"
								 data-round="8"
								 data-bg="gris"
								 data-mode="translucido"
								 v-on:click="changeDue(-1)">
								<i class="fa-thin fa-minus"
								   data-color="azul-oscuro"
								   data-size="12"/>
							</div>

							<div class="d-flex column mx-auto" data-align-items="center">
								<p class="d-flex" data-align-items="center" data-gap-h="5" data-size="16"
								   data-weight="300">
									{{ debt.meta.dues === 1 ? 'Pago único' : debt.meta.dues }}
									<span data-opacity="3"
										  v-if="debt.meta.dues !== 1"
										  data-size="12"
										  data-weight="300">cuotas</span>
								</p>

							</div>


							<div class="square centered w-20-px"
								 data-round="8"
								 data-bg="gris"
								 data-mode="translucido"
								 v-on:click="changeDue(1)">
								<i class="fa-thin fa-plus"
								   data-color="azul-oscuro"
								   data-size="12"/>
							</div>
						</div>

						<div v-if="debt.amount && debt.amount > 0 && debt.meta.dues > 1"
							 class="mt-30 d-flex py-10 px-15" data-round="15" data-gap-h="15" data-align-items="center"
							 data-bg="azul-warning">
							<i data-color="azul" class="fad fa-calculator"></i>

							<p class="mx-auto" data-weight="300" data-size="11" data-align-text="center">
								{{ $utilities.formatNumber(debt.amount, 2, 2) }}€ / {{ debt.meta.dues }} cuota(s) =
								<span data-color="azul" data-weight="600">{{
										$utilities.formatNumber(debt.amount / debt.meta.dues, 2, 2)
									}}€/cuota</span></p>

						</div>
					</div>

					<div v-if="debt.meta.dueMode == 2">
						<div class="form-group" v-bind:class="{'has-error': errors.debtAmountPerDue}">
							<label>Precio por cuota (€)</label>
							<div class="input-group">
								<input type="text" v-on:focus="delete errors.debtAmountPerDue"
									   v-model="debt.meta.amountPerDue">
							</div>
						</div>


						<div v-if="debt.amount && debt.amount > 0 && debt.meta.amountPerDue"
							 class="mt-30 d-flex py-10 px-15" data-round="15" data-gap-h="15" data-align-items="center"
							 data-bg="azul-warning">
							<i data-color="azul" class="fad fa-calculator"></i>

							<p class="mx-auto" data-weight="300" data-size="11" data-align-text="center">
								<span data-color="azul" data-weight="600">
									{{ paymentPlanPerDues.fullDues }} cuotas</span> ×
								{{ $utilities.formatNumber(debt.meta.amountPerDue, 2, 2) }}€ +
								<span data-color="azul" data-weight="600">{{
										$utilities.formatNumber(paymentPlanPerDues.finalDue, 2, 2)
									}}€</span> =
								{{ $utilities.formatNumber(debt.amount, 2, 2) }}€</p>

						</div>


					</div>

					<div class="d-flex mt-40" data-align-items="center">
						<p data-size="12" class="mr-10 no-wrap" data-weight="300" data-opacity="4">Destino</p>
						<div class="separator-horizontal"/>
					</div>

					<div class="form-group mt-10"><label for="debt-title">Distribución</label>
						<div class="input-group">
							<select v-model="debt.meta.targetMode" v-on:change="debt.meta.target = ''">
								<option value="1">Personal</option>
								<option value="2">Externo</option>
							</select>
						</div>
					</div>

					<div v-if="debt.meta.targetMode == 1">

						<div class="form-group mt-10" v-on:click="delete errors.debtTarget"
							 v-bind:class="{'has-error': errors.debtTarget}">
							<label for="">Cuenta destino</label>
							<account-selection
									v-if="accounts.length > 0"
									class="mt-10"
									:selected="debt.meta.target"
									:accounts="accountsFiltered"
									@selectOption="selectAccount"/>
						</div>

					</div>

					<div v-if="debt.meta.targetMode == 2">
						<div class="form-group mt-10"
							 v-bind:class="{'has-error': errors.debtTarget}"><label for="debt-title">Nombre
							destino</label>
							<div class="input-group"><input type="text" v-on:focus="delete errors.debtTarget"
															v-model="debt.meta.target"></div>
						</div>

					</div>

					<button type="button"
							:disabled="isLoading"
							v-on:click="registerDebt"
							class="w-100 button mt-30"
							data-bg="azul"
							data-mode="borde"
							data-behaviour="hover"
							data-hover-mode="translucido">
						<span v-if="isLoading">Un momento...</span> <span v-else>¡Registrar deuda!</span>
					</button>
				</div>
			</div>
		</div>


		<div class="d-flex" data-align-items="center">
			<div>
				<p data-size="25" data-weight="500">
					<router-link class="mr-10"
								 data-opacity="2"
								 data-opacity-behaviour="hover"
								 :to="{name: 'dashboard'}">
						<i class="far fa-arrow-left"/>
					</router-link>
					Deudas de <span data-color="naranja"
									data-weight="600">{{ accountData.name }}</span></p>
			</div>

			<button v-on:click="toggleWindowStatus(0)" class="ml-auto px-15 py-10 pointer" data-round="15"
					data-weight="300" data-bg="azul"
					data-mode="translucido"><i class="fad fa-square-plus mr-10"></i>Crear una
			</button>
		</div>

		<p class="mt-40" data-weight="600">Deudas <span data-color="azul">pendientes</span></p>

		<div id="debts-content" class="mt-20">
			<debt-card @refreshDebts="fetchAccount" @deleteDebt="deleteDebt" v-for="debt in outstandingDebts" :debt="debt" :account="accountData" v-bind:key="debt._id"/>
		</div>

		<div class="card py-20 px-30" data-bg="blanco-puro" v-if="outstandingDebts.length === 0">
			<p data-size="16" data-align-text="center" data-weight="300">
				¡Enhorabuena! ¡No tienes ninguna deuda pendiente! Añade una para organizarlas y gestionarlas con
				facilidad.</p>
		</div>

		<p class="mt-60" data-weight="600">Deudas <span data-color="naranja">terminadas</span></p>


		<div id="debts-content" class="mt-20 no-color" data-opacity="5">
			<debt-card @refreshDebts="fetchAccount" @deleteDebt="deleteDebt" v-for="debt in finishedDebts" :debt="debt" :account="accountData" v-bind:key="debt._id"/>
		</div>

		<div class="card py-20 px-30" data-bg="blanco-puro" v-if="finishedDebts.length === 0">
			<p data-size="16" data-align-text="center" data-weight="300">¡No tienes ninguna deuda terminada!</p>
		</div>

	</div>
</template>

<style scoped>
</style>