<script>
export default {
	props: ['transaction', 'account'],
	data() {
		return {
			isOpen: false,
			menu: {
				edit: 'Editar',
				delete: 'Borrar'
			}
		}
	},
	methods: {
		selectOption(item) {
			alert(item);
		},

		editTransaction() {
			if (this.transaction.transferData)
				Swal.fire({
					title: 'Ten en cuenta que...',
					icon: 'info',
					html: `Al tratarse de una transferencia a "<span data-weight="600" data-color="naranja">${this.transaction.transferData.target.name}</span>" la edición se verá reflejada en ambas cuentas`,
					confirmButtonText: '¡De acuerdo!',
					customClass: this.$storage.CUSTOM_CLASS_SWAL
				}).then(() => {
					this.$emit('editTransaction', this.transaction);
				})
			else
				this.$emit('editTransaction', this.transaction);
		},

		removeTransaction() {
			if (this.transaction.category === 'Transferencia')
				Swal.fire({
					title: 'Ten en cuenta que...',
					icon: 'info',
					html: `Al tratarse de una transferencia a "<span data-weight="600" data-color="naranja">${this.transaction.transferData.target.name}</span>" la eliminación se verá reflejada en ambas cuentas`,
					confirmButtonText: '¡De acuerdo!',
					customClass: this.$storage.CUSTOM_CLASS_SWAL
				}).then(() => {
					this.$emit('removeTransaction', this.transaction);
				})
			else
				this.$emit('removeTransaction', this.transaction);
		}
	},
	computed: {
		category() {
			return this.account.categories.find(item => item._id === this.transaction.category);
		}
	}
}
</script>

<template>
	<div class="transaction-item">
		<div class="transaction-preview pointer" v-on:click="isOpen = !isOpen">
			<div class="transactionIcon d-flex" data-align-items="center">
				<div class="square uppercase centered w-100"
					 data-round="5"
					 data-bg="naranja"
					 data-mode="translucido"
					 data-size="14">

					{{ transaction.concept.charAt(0) }}

				</div>
			</div>
			<div class="transactionConcept d-flex" data-align-items="center">
				<p class="ellipsis" data-weight="300" data-size="15">{{ transaction.concept }}</p>
			</div>

			<div class="transactionCategory d-flex" data-align-items="center" data-justify-content="center">
				<p data-weight="300" class="first-uppercase" data-size="15" data-align-text="center">{{
						category.name
					}}</p>
			</div>

			<div class="transactionDate">
				<p class="dateBig" data-weight="300" data-align-text="center" data-size="15">{{ $utilities.formatDate(transaction.dateSelected, {format: 'DD MMM [de] YYYY'}) }}</p>
				<p class="dateSmall" data-weight="300" data-align-text="center" data-size="15">{{ $utilities.formatDate(transaction.dateSelected, {format: 'DD/MMM'}) }}</p>
			</div>

			<div class="transactionAmount d-flex" data-align-items="center" data-justify-content="flex-end">
				<p :data-color="transaction.amount > 0 ? 'azul' : 'naranja'"
				   data-weight="600"
				   data-size="14"
				   data-align-text="right"
				   data-family="Montserrat">
					{{ $utilities.formatNumber(transaction.amount, 2, 2) }} €</p>
			</div>

			<div class="transactionOptions d-flex p-relative" data-align-items="center" data-justify-content="flex-end">
				<p data-align-text="right" class="pointer" data-opacity="4">
					<i :data-degrees="isOpen ? 90 : 0" class="far fa-angle-right rotated"/>
				</p>
			</div>
		</div>
		<div class="transaction-info" v-bind:class="{opened: isOpen}">
			<div class="separator-horizontal"></div>

			<div class="d-flex column mt-15" data-gap-v="10">
				<div class="d-flex infoSection">
					<p data-size="14" data-weight="600"><i class="fad fa-signature mr-10" data-color="azul"></i>Concepto
					</p>
					<p class="first-uppercase" data-size="14" data-align-text="" data-weight="300">{{ transaction.concept }}</p>
				</div>
			</div>
			<div class="d-flex column mt-15" data-gap-v="10">
				<div class="d-flex infoSection">
					<p data-size="14" data-weight="600"><i class="fad fa-list mr-10" data-color="azul"></i>Categoría
					</p>
					<p class="first-uppercase" data-size="14" data-align-text="" data-weight="300">{{ category.name }}</p>
				</div>
			</div>
			<div class="d-flex column mt-15" data-gap-v="10">
				<div class="d-flex infoSection">
					<p data-size="14" data-weight="600"><i class="fad fa-calendar mr-10" data-color="morado"></i>Fecha
						creación
					</p>
					<p class="" data-size="14" data-align-text="" data-weight="300">
						{{
							$utilities.formatDate(transaction.createdAt, {format: 'DD [de] MMMM [de] YYYY [a las] HH:mm:ss'})
						}}</p>
				</div>
			</div>

			<div class="d-flex column mt-15" data-gap-v="10">
				<div class="d-flex infoSection">
					<p data-size="14" data-weight="600"><i class="fad fa-message-dots mr-10" data-color="azul"></i>Comentario
					</p>
					<p class="" data-size="14" data-align-text="" data-weight="300">
						{{ (transaction.comment ?? '') === '' ? '&lt;Sin comentario&gt;' : transaction.comment }}</p>
				</div>
			</div>

			<div v-if="transaction.transferData">
				<div class="separator-horizontal mt-15"></div>
				<div class="d-flex infoSection mt-15">
					<p data-size="14" data-weight="600"><i class="fad fa-square-arrow-left mr-10"
														   data-color="azul"></i>Cuenta origen</p>
					<p class="" data-size="14" data-align-text="" data-weight="300">
						{{ transaction.transferData.origin.name }}</p>
				</div>

				<div class="d-flex infoSection mt-15">
					<p data-size="14" data-weight="600"><i class="fad fa-square-arrow-right mr-10"
														   data-color="naranja"></i>Cuenta destino</p>
					<p class="" data-size="14" data-align-text="" data-weight="300">
						{{ transaction.transferData.target.name }}</p>
				</div>
			</div>

			<div class="separator-horizontal my-20"></div>

			<div class="d-flex">
				<button v-on:click="editTransaction"
						class="button pointer py-10 px-20 ml-auto"
						data-bg="azul"
						data-mode="borde"
						data-behaviour="hover"
						data-hover-mode="translucido">
					<i class="fad fa-pen-line mr-10"></i> Editar
				</button>

				<button v-on:click="removeTransaction"
						class="button pointer py-10 px-20 ml-20"
						data-bg="naranja"
						data-mode="borde"
						data-behaviour="hover"
						data-hover-mode="translucido">
					<i class="fad fa-trash mr-10"></i> Eliminar
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>