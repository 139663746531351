import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ServicesView from "@/views/ServicesView.vue";
import AreaClient from "@/views/AreaClient.vue";
import DashboardView from "@/views/DashboardView.vue";
import AccountsView from "@/views/DashboardView.vue";
import TransactionsView from "@/views/TransactionsView.vue";
import ProfileView from "@/views/ProfileView.vue";
import DebtsView from "@/views/DebtsView.vue";

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			title: '¡Controla tus finanzas!',
			requiresAuth: false}
	},
	{
		path: '/services',
		name: 'services',
		component: ServicesView,
		meta: {
			title: '¿Qué te ofrecemos?',
			requiresAuth: false}
	},
	{
		path: '/area-client',
		name: 'area-client',
		component: AreaClient,
		meta: {
			title: 'Área de cliente',
			requiresAuth: false}
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: AccountsView,
		meta: {
			title: 'Escritorio',
			requiresAuth: true}
	},
	{
		path: '/profile',
		name: 'profile',
		component: ProfileView,
		meta: {
			title: 'Mi perfil',
			requiresAuth: true}
	},
	{
		path: '/account/:accountId/debts',
		name: 'debts',
		component: DebtsView,
		meta: {
			title: 'Tus deudas',
			requiresAuth: true,
			group: 'accounts'
		}
	},
	{
		path: '/account/:accountId/transactions',
		name: 'transactions',
		component: TransactionsView,
		meta: {
			title: 'Tus transacciones',
			requiresAuth: true,
			group: 'accounts'
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
