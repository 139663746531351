<script>
export default {
	props: ['categories', 'account'],
	data() {
		return {
			categorySelected: 0,
			value: '',
			isSave: false,
			errors: {}
		}
	},
	watch: {
		account() {
			let limits = this.account.categoriesLimits ?? {};
			this.value = limits[this.category] ?? '';

		}
	},
	methods: {
		changeCategory(value) {
			let newCategory = this.categorySelected + value;

			if (newCategory < 0) newCategory = this.categories.length - 1;
			else if (newCategory > this.categories.length - 1) newCategory = 0;

			this.categorySelected = newCategory;
			this.setValue();
		},

		setValue() {
			let limits = this.account.categoriesLimits ?? [];

			this.value = limits[this.categories[this.categorySelected]] ?? '';
		},
		saveLimit() {
			this.errors.value = isNaN(this.value) ? 'El nombre de la cuenta es obligatorio' : undefined;

			if (!Object.values(this.errors).some(error => error !== undefined)) {
				this.$emit('addLimit', {value: this.value, category: this.category});
				this.isSave = true;

				setInterval(() => {
					this.isSave = false;
				}, 3000)
			}
		}
	},
	computed: {
		category() {
			return this.categories[this.categorySelected];
		}
	},
	mounted() {

	}
}
</script>

<template>
	<div>
		<div class="d-flex" data-align-items="center" data-gap-h="5">
			<p class="mr-auto" data-weight="600" data-size="18">{{ category }}</p>

			<button data-round="8" class="pointer px-10 py-5 ml-10" v-on:click="changeCategory(-1)"
					data-bg="naranja" data-mode="borde" data-behaviour="hover"
					data-hover-mode="translucido"><i
					class="fas fa-angle-left"></i></button>
			<button data-round="8" class="pointer px-10 py-5" v-on:click="changeCategory(1)" data-bg="naranja"
					data-mode="borde" data-behaviour="hover" data-hover-mode="translucido"><i
					class="fas fa-angle-right"></i></button>
		</div>

		<div class="form-group mt-10 d-grid" data-align-items="center" v-bind:class="{'has-error': errors.value}">
			<label for="">Límite</label>
			<div class="input-group mt-5">
				<input v-on:focus="delete errors.value" placeholder="Sin límite" type="text" v-model="value">
			</div>
		</div>

		<div class="d-grid mt-40" data-columns="2">
			<div class="d-flex" data-align-items="center">
				<div class="d-flex py-10 px-15" v-if="isSave" data-round="15" data-gap-h="15"
					 data-align-items="center"
					 data-bg="verde-warning">
					<i data-color="verde" class="fad fa-circle-check"></i>

					<p data-size="11" data-weight="300">Guardado</p>
				</div>
			</div>
			<div class="d-flex" data-align-items="center">
				<button class="ml-auto py-10 px-20 pointer"
						v-on:click="saveLimit"
						data-bg="azul"
						data-behaviour="hover"
						data-hover-mode="translucido"
						data-round="10"
						data-mode="borde">Guardar límite
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>