import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment';
import axios from 'axios';

import Papa from 'papaparse';

import readXlsxFile from 'read-excel-file'

import './libs/fontawesome6';
import Swal from 'sweetalert2'
import * as storage from './utils/storage';
import * as utilities from './utils/utilities';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";


import './styles/main.scss';
import Navigation from "@/components/Navigation.vue";
import Banner from "@/components/Banner.vue";
import CardCover from "@/components/CardCover.vue";
import TransactionForm from "@/components/TransactionForm.vue";
import CustomSelection from "@/components/CustomSelection.vue";
import TransactionItem from "@/components/TransactionItem.vue";
import AccountSelection from "@/components/AccountSelection.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import ImportTransactionsForm from "@/components/ImportTransactionsForm.vue";
import GraphTransactionsResume from "@/components/GraphTransactionsResume.vue";
import CategoryLimit from "@/components/CategoryLimit.vue";
import LimitsForm from "@/components/LimitsForm.vue";
import DebtCard from "@/components/DebtCard.vue";

window.Swal = Swal;
window.axios = axios;
window.moment = moment;
window.am5 = am5;
window.am5xy = am5xy;
window.readXlsxFile = readXlsxFile;
window.Papa = Papa;


const app = createApp(App);
app.config.globalProperties.$storage = storage;
app.config.globalProperties.$utilities = utilities;

app.component('navigation', Navigation);
app.component('banner', Banner);
app.component('card-cover', CardCover);
app.component('transaction-form', TransactionForm);
app.component('custom-selection', CustomSelection);
app.component('transaction-item', TransactionItem);
app.component('account-selection', AccountSelection);
app.component('footer-component', FooterComponent);
app.component('import-transactions-form', ImportTransactionsForm);
app.component('graph-transactions-resume', GraphTransactionsResume);
app.component('category-limit', CategoryLimit);
app.component('limits-form', LimitsForm);
app.component('debt-card', DebtCard);

// Middleware
router.beforeEach((to, from, next) => {
	const isAuthenticated = !!localStorage.getItem('sessionToken');

	 document.title = `Beneka - ${to.meta.title}`;

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!isAuthenticated) {
			next('/');
		} else {
			next();
		}
	} else {
		if (isAuthenticated) {
			window.location = '/dashboard';
		} else {
			next();
		}
	}
});


app.use(router).mount('#app')
