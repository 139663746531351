<script>
export default {
	props: ['userData'],
	data() {
		return {
			isLoading: false,
			showEmailAlert: false,

			imagePreview: null,
			tabSelected: 0,
			editPersonal: false,
			personalInfo: {},
			passwordData: {
				current: '',
				newPassword: '',
				confirmPassword: '',
			},
			seePassword: {
				current: false,
				newPassword: false,
				confirmPassword: false,
			},

			errors: {}
		}
	},
	methods: {
		logout() {
			localStorage.removeItem('sessionToken');
			localStorage.removeItem('userData');

			window.location = '/';
		},

		showAlert() {
			delete this.errors.email;

			if (!this.showEmailAlert) {
				this.showEmailAlert = true;
				Swal.fire({
					title: 'Ten en cuenta que...',
					icon: 'warning',
					text: 'Si cambias el correo electrónico tu sesión se cerrará y tendrás que volver a verificar tu cuenta para poder entrar en la aplicación',
					customClass: this.$storage.CUSTOM_CLASS_SWAL
				})
			}
		},


		openFileInput() {
			this.$refs.fileInput.click();
		},
		// Maneja el cambio en el input de tipo archivo
		handleFileInputChange(event) {
			const file = event.target.files[0];

			if (file) {
				this.imagePreview = URL.createObjectURL(file);
				this.uploadProfileImage(file);
			} else {
				this.imagePreview = null;
			}
		},
		uploadProfileImage(file) {
			const formData = new FormData();
			formData.append('file', file);

			axios.post(`${this.$storage.API_URL}/api/user/update-image`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
				}
			}).then((res) => {
				localStorage.setItem('userData', JSON.stringify(res.data.user));
			})
		},
		updateBasicData() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {name, gender, email, phone} = this.personalInfo;
				this.errors = {};

				this.errors.name = !name.trim() ? 'El nombre es obligatorio' : undefined;
				this.errors.email = !email.trim() ? 'El correo electrónico es obligatorio' : !this.$utilities.isValidEmail(email) ? 'El correo electrónico no es válido' : undefined;
				this.errors.phone = !phone.trim() ? 'El teléfono es obligatorio' : !this.$utilities.isValidPhone(phone) ? 'El teléfono no es válido' : undefined;

				if (!Object.values(this.errors).some(error => error !== undefined)) {
					axios.put(`${this.$storage.API_URL}/api/user/update-basic-data`, this.personalInfo, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
						localStorage.setItem('userData', JSON.stringify(res.data.user));

						this.editPersonal = false;

						this.isLoading = false;

						if (res.data.user.email !== this.userData.email) this.logout();
						else this.$emit('refreshUser');
					}).catch((er) => {
						this.errors = er.response.data.errors;
						this.isLoading = false;

					})
				} else
					this.isLoading = false;
			}
		},
		updatePassword() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {current, newPassword, confirmPassword} = this.passwordData;
				this.errors = {};

				this.errors.current = !current.trim() ? 'La contraseña actual es obligatoria' : undefined;
				this.errors.newPassword = !newPassword.trim() ? 'La contraseña es obligatoria' : !this.$utilities.isValidPassword(newPassword) ? 'La contraseña no cumple con los requisitos' : undefined;
				this.errors.confirmPassword = !confirmPassword.trim() ? 'La confirmación de la contraseña es obligatoria' : newPassword !== confirmPassword ? 'Las contraseñas no coinciden' : undefined;


				if (!Object.values(this.errors).some(error => error !== undefined)) {
					axios.put(`${this.$storage.API_URL}/api/user/update-password`, this.passwordData, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
						Swal.fire({
							title: '¡Genial!',
							icon: 'success',
							text: 'Tu contraseña ha sido cambiada exitosamente',
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						})

						this.passwordData = {
							current: '',
							newPassword: '',
							confirmPassword: '',
						}
					}).catch((er) => {
						this.errors = er.response.data.errors;
						this.isLoading = false;

					})
				} else
					this.isLoading = false;
			}
		}
	},
	mounted() {
		const {name, email, gender, phone} = JSON.parse(localStorage.getItem('userData'));
		this.personalInfo = {name, email, gender, phone};
	},
	computed: {}
}
</script>

<template>
	<div id="profile-content">
		<div class="tab-group">
			<div v-if="false"
				 class="tab"
				 v-on:click="tabSelected = 0" v-bind:class="{selected: tabSelected === 0}">
				<p>
					<i data-opacity="4" class="mr-10 fad fa-address-card"/>Mi cuenta
				</p>
			</div>

			<!--			<div class="tab" v-on:click="tabSelected = 2" v-bind:class="{selected: tabSelected === 2}">-->
			<!--				<p><i data-opacity="4" class="mr-10 fad fa-user"></i>Notificaciones</p>-->
			<!--			</div>-->

			<div class="py-15 w-100 pointer"
				 v-on:click="logout"
				 data-round="15"
				 data-bg="naranja-transparent"
				 data-border-color="naranja"
				 data-align-text="center">
				<p data-color="naranja"
				   data-size="16"
				   data-weight="400">
					<i data-opacity="4" class="mr-10 fad fa-door-open"/>
					Cerrar sesión
				</p>
			</div>


		</div>
		<div>
			<div v-if="tabSelected === 0">
				<div class="cardProfile d-flex py-25 px-40"
					 data-gap-h="30"
					 data-round="25"
					 data-align-items="center"
					 data-bg="blanco-puro"
					 data-border-color="blanco-sucio">
					<div class="preview-image w-100-px h-100-px d-flex"
						 data-round="50"
						 data-border-color="blanco-sucio">
						<img :src="imagePreview ? imagePreview : `/assets/profile_images/${userData.profileImage ?? 'default.jpg'}`">
					</div>

					<div>
						<p class="nameProfile"
						   data-size="20"
						   data-weight="500">{{ userData.name }}
						</p>
						<p class="emailProfile mt-10"
						   data-size="14"
						   data-weight="300">
							{{ userData.email }}
						</p>
						<p class="phoneProfile"
								data-size="14"
						   data-weight="300">
							{{ userData.phone }}
						</p>
					</div>

					<div class="d-flex ml-auto" data-align-items="center">
						<input type="file" ref="fileInput" class="d-none" v-on:change="handleFileInputChange"
							   accept="image/jpeg">
						<button v-if="false" v-on:click="openFileInput"
								class="button pointer py-10 px-20 ml-auto"
								data-bg="blanco-puro"
								data-border-color="azul">
							<i class="fad fa-upload mr-10" data-color="azul" data-opacity="7"/> <span data-color="azul">Subir imagen</span>
						</button>
					</div>

				</div>

				<div class="cardProfile py-25 px-40 mt-30" data-gap-h="30" data-round="25" data-align-items="center"
					 data-bg="blanco-puro"
					 data-border-color="blanco-sucio">
					<div class="d-flex" data-align-items="center">
						<p data-weight="600" data-size="18">Datos <span data-color="naranja">personales</span></p>


						<div class="d-flex ml-auto" data-align-items="center">
							<button v-on:click="editPersonal = true" v-if="!editPersonal"
									class="button pointer py-10 px-20 ml-auto"
									data-bg="blanco-puro"
									data-border-color="azul">
								<i class="fad fa-pen-line" data-color="azul" data-opacity="7"/>
								<span class="textButton ml-10" data-color="azul">Editar</span>
							</button>
							<button v-on:click="updateBasicData" v-else
									class="button pointer py-10 px-20 ml-auto"
									data-bg="azul"
									data-mode="translucido">
								<i class="fad fa-floppy-disk mr-10"
								   data-color="azul"/>
								<span data-color="azul">Guardar</span>
							</button>
						</div>
					</div>

					<div class="mt-20 profile-content">
						<div>
							<div class="form-group" v-bind:class="{'has-error': errors.name}">
								<label for="" data-opacity="6">Nombre</label>
								<p v-if="!editPersonal" data-weight="500">{{ userData.name }}</p>
								<div v-else class="input-group w-50">
									<input v-on:focus="delete errors.name" type="text" v-model="personalInfo.name">
								</div>
							</div>
						</div>


						<div>
							<div class="form-group" v-bind:class="{'has-error': errors.gender}">
								<label for="" data-opacity="6">Género</label>
								<p v-if="!editPersonal" data-weight="500">
									{{ userData.gender === 'M' ? 'Masculino' : 'Femenino' }}</p>
								<div v-else class="input-group w-50">
									<select v-model="personalInfo.gender">
										<option value="M">Masculino</option>
										<option value="F">Femenino</option>
										<option value="O">No especificar</option>
									</select>
								</div>
							</div>
						</div>

						<div>
							<div class="form-group" v-bind:class="{'has-error': errors.email}">
								<label for="" data-opacity="6">Email</label>
								<p v-if="!editPersonal" data-weight="500">{{ userData.email }}</p>
								<div v-else class="input-group w-50">
									<input v-on:focus="showAlert" type="text" v-model="personalInfo.email">
								</div>
							</div>
						</div>


						<div>
							<div class="form-group" v-bind:class="{'has-error': errors.phone}">
								<label for="" data-opacity="6">Teléfono</label>
								<p v-if="!editPersonal" data-weight="500">{{ userData.phone }}</p>
								<div v-else class="input-group w-50">
									<input v-on:focus="delete errors.phone" type="text" v-model="personalInfo.phone">
								</div>
							</div>
						</div>
					</div>


				</div>

				<div class="cardProfile py-25 px-40 mt-30" data-gap-h="30" data-round="25" data-align-items="center"
					 data-bg="blanco-puro"
					 data-border-color="blanco-sucio">
					<div class="d-flex" data-align-items="center">
						<p data-weight="600" data-size="18"><span data-color="naranja">Seguridad</span> de tu cuenta</p>


						<div class="d-flex ml-auto" data-align-items="center">
							<button class="button pointer py-10 px-20 ml-auto"
									v-on:click="updatePassword"
									data-bg="azul"
									data-mode="translucido">
								<i class="fad fa-floppy-disk"
								   data-color="azul"/>
								<span class="textButton ml-10" data-color="azul">Guardar</span>
							</button>
						</div>
					</div>

					<div class="mt-20 profile-content">
						<div>
							<div class="form-group" data-style="linea" v-bind:class="{'has-error': errors.current}">
								<label for="" data-opacity="6">Contraseña actual</label>
								<div class="input-group w-50">
									<input v-on:focus="delete errors.current"
										   :type="seePassword.current ? 'text' : 'password'"
										   v-model="passwordData.current">
									<i class="ml-10 fa-thin fa-eye pointer"
									   v-on:mouseenter="seePassword.current = true"
									   v-on:mouseleave="seePassword.current = false"></i>
								</div>
							</div>
						</div>


						<div>
							<div class="form-group" data-style="linea" v-bind:class="{'has-error': errors.newPassword}">
								<label for="" data-opacity="6">Contraseña nueva</label>
								<div class="input-group w-50">
									<input v-on:focus="delete errors.newPassword"
										   :type="seePassword.newPassword ? 'text' : 'password'"
										   v-model="passwordData.newPassword">
									<i class="ml-10 fa-thin fa-eye pointer"
									   v-on:mouseenter="seePassword.newPassword = true"
									   v-on:mouseleave="seePassword.newPassword = false"></i>
								</div>
							</div>
						</div>


						<div>
							<div class="form-group" data-style="linea"
								 v-bind:class="{'has-error': errors.confirmPassword}">
								<label for="" data-opacity="6">Confirma contraseña nueva</label>
								<div class="input-group w-50">
									<input v-on:focus="delete errors.confirmPassword"
										   :type="seePassword.confirmPassword ? 'text' : 'password'"
										   v-model="passwordData.confirmPassword">
									<i class="ml-10 fa-thin fa-eye pointer"
									   v-on:mouseenter="seePassword.confirmPassword = true"
									   v-on:mouseleave="seePassword.confirmPassword = false"></i>
								</div>
							</div>
						</div>
					</div>


				</div>


			</div>

			<div v-if="tabSelected === 1">

			</div>

			<div v-if="tabSelected === 2">

			</div>
		</div>
	</div>
</template>

<style scoped>
</style>