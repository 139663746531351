<script>
export default {
	props: ['account'],
	data() {
		return {
			cloneAccount: undefined,
			importData: {
				template: 'universal'
			},

			rows: 0,
			fileData: {
				file: '',
				content: [],
				parsed: false
			},

			table: [],
			errors: {},
			errorsTable: {},
			categories: this.$storage.TRANSACTION_TYPES,

		}
	},
	methods: {
		changeInputRows(e) {
			this.errors = {};
			let number = e.target.value * 1;

			if (isNaN(number) || e.target.value * 1 !== number || !e.target.value) {
				this.errors.rows = 'Debe ser un número';
				number = 10
			}

			this.updateTable(number);
		},

		updateTable(number) {
			let table = [];
			if (this.rows < number) {
				table = this.table;
				for (let i = table.length; i < number; i++) {
					table[i] = {
						concept: '',
						category: this.cloneAccount.categories.find(item => item.name === 'general')._id,
						date: '',
						amount: '',
						status: 'pending'
					}
				}

				this.table = table;
				this.rows = number;
			} else {
				table = this.table.slice(0, number);

				this.table = table;
				this.rows = number;
			}

			// this.checkTable();

		},

		checkTable() {
			this.table.forEach((item, key) => {
				item.status = 'ok';
				if (!item.concept.trim("")) item.status = 'error';
				if (item.date === '' || item.date === 'Invalid date') item.status = 'error';

			})
		},


		resetTable() {
			let table = [];

			for (let i = table.length; i < 10; i++) {
				table[i] = {
					concept: '',
					category: this.cloneAccount.categories.find(item => item.name === 'general')._id,
					date: '',
					amount: '',
					status: 'pending'
				}

			}

			this.table = table;
			this.rows = 10;
		},

		processFile(e) {
			this.fileData = {
				file: '',
				content: [],
				parsed: false
			}
			this.errors = {};

			let file = e.target.files[0];
			let extension = file.name.split(".").pop();

			if (extension === 'csv') {
				this.fileData.file = file;
				let that = this;

				Swal.fire({
					title: 'Ten en cuenta que...',
					icon: 'info',
					html: `Algunos conceptos pueden no haberse importado correctamente. Échale un ojo a los datos y revisa caracteres.`,
					confirmButtonText: '¡Se lo echaré!',
					customClass: this.$storage.CUSTOM_CLASS_SWAL
				}).then(async () => {
					await Papa.parse(this.fileData.file, {
						header: true,
						skipEmptyLines: true,
						encoding: 'UTF-8',
						complete: function (results) {
							that.fileData.content = results;
							that.fileData.parsed = true;
						}.bind(this.fileData)
					});

					setTimeout(() => {
						let categoriesCSV = [...new Set(this.fileData.content.data.map(item => item.Categoria.toLowerCase()))];
						const categoriesAccount = this.cloneAccount.categories.map(item => item.name);

						let newCategories = categoriesCSV
								.filter(item => !categoriesAccount.includes(item))
								.map(item => {
									return {
										_id: item,
										name: item,
										limit: 0,
										canDelete: true
									}
								});


						if (newCategories.length > 0) {
							Swal.fire({
								title: '¡Un segundo!',
								icon: 'warning',
								html: `Hay categorías en tu fichero que no se encuentran en el listado. Éstas serán añadidas y guardadas en tu cuenta.`,
								confirmButtonText: '¡Perfecto!',
								customClass: this.$storage.CUSTOM_CLASS_SWAL
							})
						}

						this.cloneAccount.categories = this.cloneAccount.categories.concat(newCategories);

						switch (this.importData.template) {
							case "universal":
								this.setUniversalTemplate();
								this.checkTable();
								break;
							case "imagin":
								this.setImaginTemplate();
								this.checkTable();
								break;
						}
					}, 1000)

				})

			} else {
				this.errors.file = `Vaya... el fichero debe estar en formato CSV, y tú nos diste un ${extension.toUpperCase()}`
			}

		},

		setUniversalTemplate() {
			this.updateTable(this.fileData.content.data.length);

			this.errorsTable = {};

			this.fileData.content.data.forEach((item, key) => {
				let categoryData = this.cloneAccount.categories.find(category => category.name === item.Categoria.replace(/[^\x00-\x7F]/g, '').toLowerCase());
				// console.log(item.Categoria,item.Categoria.replace(/[^\x00-\x7F]/g, ''), this.cloneAccount.categories);

				let concept = this.$utilities.capitalizeString(item.Concepto.replace(/[^\x00-\x7F]/g, ''));
				let category = categoryData._id;
				let date = item.Fecha !== '' ? moment(item.Fecha, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
				let amount = parseFloat(item.Importe.replace(',', '.'));
				let status = 'pending';

				this.table[key] = {concept, category, date, amount, status};
			})


		},
		setImaginTemplate() {
			this.updateTable(this.fileData.content.data.length);

			this.errorsTable = {};

			this.fileData.content.data.forEach((item, key) => {
				let concept = this.$utilities.capitalizeString(item.Concepto.replace(/[^\x00-\x7F]/g, ''));
				let category = 'General';
				let date = item.Fecha !== '' ? moment(item.Fecha, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm') : '';
				let amount = parseFloat(item.Importe.replace('.', '').replace(',', '.').replace('EUR', ''));
				let status = 'pending';

				this.table[key] = {concept, category, date, amount, status};
			})


		},
		saveTransactions() {
			this.checkTable();

			let hasErrors = this.table.filter(item => item.status !== 'ok');

			if (hasErrors.length === 0) {
				this.$emit('importTransactions', this.table);

				this.resetTable();
			}

		}
	},
	computed: {},
	mounted() {

		this.cloneAccount = {...this.account};
		this.updateTable(10);
	}
}
</script>

<template>
	<div id="import-transactions">
		<div>
			<p data-weight="300" data-size="14">¿Quieres <span data-weight="600" data-color="naranja">importar</span>
				tus movimientos desde un fichero CSV?</p>

			<div class="form-group mt-20">
				<label for="">Tipo de plantilla</label>
				<div class="input-group">
					<select v-model="importData.template" name="" id="">
						<option value="universal">Universal</option>
						<option value="cajasur">Cajasur</option>
						<option value="imagin">Imagin</option>
					</select>
				</div>
			</div>

			<p v-if="importData.template === 'universal'" class="mt-20" data-size="14" data-weight="300">Está
				seleccionando la plantilla <span data-color="azul" data-weight="600">Universal</span>. Descargue esta
				misma plantilla desde
				<a data-color="naranja" class="underline" href="/assets/files/PLANTILLA%20BENEKA%20MOVIMIENTOS.csv">aquí
					mismo</a> y rellénela con los datos en su columna correcta. Después importa la plantilla rellenada en <span data-color="naranja">Importar archivo</span>.
			</p>

			<p class="mt-15" data-opacity="4" data-size="10">La versión móvil tiene restringida por cuestiones técnicas la edición en vivo de la plantilla seleccionada. Usa la versión de escritorio para previsualizar la información.</p>

			<div class="mt-auto">
				<div v-if="fileData.file" class="mt-20 d-flex py-10 px-15" data-round="15" data-gap-h="15"
					 data-align-items="center"
					 data-bg="verde-warning">
					<i data-color="verde" class="fad fa-circle-check"></i>

					<p data-size="11" data-weight="300">Has importado correctamente la información del fichero <span
							data-weight="600">{{ fileData.file.name.split(".")[0] }}</span></p>
				</div>

				<div v-if="errors.file" class="mt-20 d-flex py-10 px-15" data-round="15" data-gap-h="15"
					 data-align-items="center"
					 data-bg="naranja-warning">
					<i data-color="naranja" class="fad fa-circle-xmark"></i>

					<p data-size="11" data-weight="300">{{ errors.file }}</p>
				</div>


				<div class="button pointer mt-20" v-on:click="delete errors.file; $refs.inputFile.click()"
					 data-align-text="center"
					 data-bg="naranja" data-mode="borde"
					 data-hover-mode="translucido" data-behaviour="hover">
					<i class="fad fa-file-import mr-10"></i>Importar archivo

					<input ref="inputFile" class="d-none" type="file"
						   v-on:change="processFile"
						   accept=".csv">
				</div>

				<div v-if="fileData.parsed" class="button pointer saveMovements"
					 v-on:click="saveTransactions"
					 data-align-text="center"
					 data-bg="azul"
					 data-mode="borde">
					<i class="fad fa-floppy-disk mr-10"></i>Guardar {{ rows }} movimientos
				</div>
			</div>

			<!--			<div class="button mt-auto" data-align-text="center" data-bg="naranja" data-mode="borde">-->
			<!--				¡Procesar datos!-->
			<!--			</div>-->
		</div>

		<div class="separator-vertical"/>


		<div class="scroll-zone pr-20">
			<div class="d-flex" data-gap-h="20">
				<div class="form-group" v-bind:class="{'has-errors': errors.rows}">
					<label for="">Número de filas</label>
					<div class="input-group">
						<input type="number" v-on:focus="delete errors.rows" :value="rows"
							   v-on:change="changeInputRows" min="1">
					</div>
				</div>

				<div class="ml-auto d-flex" data-align-items="center">
					<div class="button pointer"
						 v-on:click="saveTransactions"
						 data-align-text="center"
						 data-bg="azul"
						 data-mode="borde">
						<i class="fad fa-floppy-disk mr-10"></i>¡Guardar movimientos!
					</div>
				</div>


			</div>

			<div class=" mt-20 d-flex py-10 px-15" data-round="15" data-gap-h="15" data-align-items="center"
				 data-bg="amarillo-warning">
				<i data-color="naranja" class="fad fa-lightbulb"></i>

				<p data-size="11" data-weight="300">Revisa cada campo y fíjate en que la fecha esté bien escrita.
					Recuerda que puedes modificar las categorías en tu perfil.</p>
			</div>

			<div class="transactions-table-container mt-30">
				<table class="transactions-table">
					<tr>
						<th><p data-weight="300" data-align-text="left" data-size="16">Concepto</p></th>
						<th><p data-weight="300" data-align-text="left" data-size="16">Categoría</p></th>
						<th><p data-weight="300" data-align-text="left" data-size="16">Fecha</p></th>
						<th><p data-weight="300" data-align-text="left" data-size="16">Importe</p></th>
						<th></th>
					</tr>

					<tr v-for="(row,  rowNumber) in table" class="has-error">
						<td>
							<input v-on:focus="row.status = 'pending'"
								   v-model="row.concept" data-size="14"
								   type="text">
						</td>
						<td>
							<select data-size="14" v-model="row.category">
								<option v-for="category in cloneAccount.categories" :value="category._id">{{ $utilities.capitalizeString(category.name) }}</option>
							</select>
						</td>
						<td>
							<input v-on:focus="row.status = 'pending'" data-size="14" v-model="row.date"
								   type="date">
						</td>
						<td>
							<input data-align-text="right" v-on:focus="row.status = 'pending'" data-size="14" v-model="row.amount"
								   type="text">
						</td>
						<td>
							<i
									v-if="row.status === 'pending'"
									class="far fa-clock"
									data-color="azul"/>
							<i v-if="row.status === 'ok'"
							   class="far fa-circle-check"
							   data-color="verde"/>
							<i v-if="row.status === 'warning'"
							   class="far fa-circle-exclamation"
							   data-color="amarillo-pollo"/>
							<i v-if="row.status === 'error'"
							   class="far fa-circle-xmark"
							   data-color="naranja"/>
						</td>

					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>