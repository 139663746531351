<script>
export default {
  props: [],
  data() {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<template>
  <div class="banner">
    <h1 data-color="azul-oscuro">Domina tus finanzas<br><span data-weight="700" data-color="azul">al máximo</span></h1>
    <p class="mt-30 mb-50">Cómodo, útil y necesario</p>

    <router-link :to="{name:'area-client'}" class="button mb-80" data-bg="azul-oscuro" data-mode="borde" data-behaviour="hover" data-weight="300">Hazte cliente</router-link>

    <img class="mobile-image" src="/assets/recursos/mobile.png" alt="">
  </div>
</template>

<style scoped>
</style>