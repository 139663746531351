<script>
export default {
	props: ['transactions', 'range'],
	data() {
		return {
			pixelRangeMin: 20,
			pixelRangeMax: 100,
		}
	},
	methods: {},
	mounted() {
	},
	computed: {
		data() {
			const dailyTransactions = this.transactions.reduce((acc, transaction) => {
				const date = transaction.dateSelected.split(' ')[0];
				if (!acc[date]) {
					acc[date] = 0;
				}
				acc[date] += transaction.amount;
				return acc;
			}, {});

			// Calcular el valor máximo y mínimo
			const values = Object.values(dailyTransactions);
			let maxValue = Math.max(...values);
			let minValue = Math.min(...values);

			// Ajustar el valor máximo si es necesario
			if (maxValue < this.pixelRangeMax) {
				maxValue = this.pixelRangeMax;
			}

			// Calcular la altura para cada día
			const heights = {};
			const currentDate = new Date(this.range.from);
			const toDate = new Date(this.range.to);
			while (currentDate <= toDate) {
				const dateStr = currentDate.toISOString().split('T')[0];
				const value = dailyTransactions[dateStr];
				if (value !== undefined) {
					// Calcular la altura asegurándose de que nunca sea menor que pixelRangeMin
					let height = ((value - minValue) / (maxValue - minValue)) * (this.pixelRangeMax - this.pixelRangeMin) + this.pixelRangeMin;
					height = Math.max(height, this.pixelRangeMin);
					heights[dateStr] = {
						height: height.toFixed(2) + 'px',
						amount: value
					};
				} else {
					heights[dateStr] = undefined;
				}
				currentDate.setDate(currentDate.getDate() + 1);
			}

			return heights;
		}
	}

}
</script>

<template>
	<div class="graph-transactions d-flex" data-align-items="center">
		<p class="w-100" data-weight="300" data-align-text="center" v-if="Object.keys(data).length === 0">¡No hay
			registros para mostrar en la gráfica!</p>
		<div class="column-bar" v-for="item in data"
			 :data-bg="!item ? 'blanco-sucio' : item.amount < 0 ? 'naranja' : 'azul'"
			 data-mode="translucido"
			 :style="`height: ${!item ? `${pixelRangeMin}px` : item.height}`">
		</div>
	</div>
</template>

<style scoped>
</style>