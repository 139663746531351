
export function isValidEmail(email) {
	// Utiliza una expresión regular para validar el formato del correo electrónico
	let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}

export function isValidPhone(phone) {
	// Utiliza una expresión regular para validar el formato del teléfono
	let phoneRegex = /^[67]\d{8}$/;
	return phoneRegex.test(phone);
}

export function isValidPassword(password) {
	// Utiliza expresiones regulares para validar que la contraseña cumpla con los requisitos
	let uppercaseRegex = /[A-Z]/;
	let lowercaseRegex = /[a-z]/;
	let numberRegex = /\d/;

	return (
			password.length >= 8 &&
			uppercaseRegex.test(password) &&
			lowercaseRegex.test(password) &&
			numberRegex.test(password)
	);
}

export function formatDate(date, config = {}) {
	if (typeof (date) === 'string')
		date = moment(date);

	let format = config.format ?? 'DD MMM YYYY HH:mm';

	return date.locale('es').format(format);

}

export function formatNumber(number, minDecimals, maxDecimals) {
	number = parseFloat(number);


	let decimalSeparator = "'";
	let thousandSeparator = ".";
	let numDecimals = Math.min(Math.max(minDecimals, countDecimals(number)), maxDecimals);
	let integerPart = Math.trunc(number).toString();
	let decimalPart = "";

	if (numDecimals > 0) {
		decimalPart = number.toFixed(numDecimals).slice(integerPart.length + 1).replace(".", decimalSeparator);
	}

	let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
	return decimalPart ? `${formattedIntegerPart}${decimalSeparator}${decimalPart}` : formattedIntegerPart;
}

export function isLoggedIn() {
	return !!localStorage.getItem('sessionToken');
}

export function capitalizeString(str) {
	if (!str) return '';
	return str[0].toUpperCase() + str.toLowerCase().slice(1);
}

export function getTextDate(date) {
	const momentDate = moment(date);
	const today = moment().startOf('day');
	const yesterday = moment().subtract(1, 'days').startOf('day');
	const daysAgo = today.diff(momentDate, 'days');

	if (momentDate.isSame(today, 'day')) return 'Hoy';
	else if (momentDate.isSame(yesterday, 'day')) return 'Ayer';
	else return `Hace ${daysAgo} días`;
}


// --- 🚀private

function countDecimals(number) {
	if (Math.floor(number) === number) return 0;
	return number.toString().split(".")[1].length || 0;
}