<script>
export default {
	props: [],
	data() {
		return {
			componentLoaded: false,
			showSuccesfulMessage: false,
			accountData: {
				info: {},
				transactions: [],
			},

			transactionsPagination: {
				itemsPerPage: 20,
				currentPage: 1,
			},
			filters: {
				searchBy: '',
				sortBy: 'createdAt',
				rangeMode: 'all',
				ranges: {
					from: '',
					to: '',
				}
			},

			transactionData: {
				concept: '',
				account: '',
				amount: '',
				dateSelected: moment().format('YYYY-MM-DD'),
				comment: '',
				category: '',
			},

			transactionEdit: undefined,

			filtersTemp: {},
			windowData: {
				show: false,
				section: 0,
			},
			errors: {},

			windowForm: {
				show: false,
				section: 0,
			}
		}
	},
	methods: {
		// Datos
		fetchAccount() {
			axios.post(`${this.$storage.API_URL}/api/account/${this.$route.params.accountId}`, this.account, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.accountData.info = res.data.account;
				this.transactionData.category = this.accountData.info.categories.find(item => item.name === 'general')._id;
				this.componentLoaded = true;
			});
		},
		fetchTransactions(updateFilter) {
			axios.post(`${this.$storage.API_URL}/api/transaction/${this.$route.params.accountId}`, this.account, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.accountData.transactions = res.data.transactions;

				if (updateFilter)
					this.setDatesFilters();

				// this.loadGraph();
			});
		},
		createTransaction(data) {
			if (!this.isLoading) {
				this.isLoading = true;
				axios.post(`${this.$storage.API_URL}/api/transaction/${this.accountData.info._id}/create`, data, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
					this.isLoading = false;
					Swal.fire({
						title: '¡Perfecto!',
						icon: 'success',
						text: 'El movimiento ha sido añadido a la cuenta de forma correcta',
						customClass: this.$storage.CUSTOM_CLASS_SWAL
					})

					this.transactionData = {
						concept: '',
						amount: '',
						comment: '',
						category: '',
					};

					this.fetchTransactions();
				}).catch((er) => {
					this.isLoading = false;
				})
			}
		},

		// Gráficas
		loadGraph() {
			am5.array.each(am5.registry.rootElements, function (root) {
				if (root.dom.id === "resumeGraph") {
					root.dispose();
				}
			});

			this.rangeChart = {};

			let root = am5.Root.new("resumeGraph");
			root._logo.dispose();
			let chart = root.container.children.push(am5xy.XYChart.new(root, {
				layout: root.verticalLayout,
				panX: false,
				panY: false,
				wheelX: "none",
				wheelY: "none",
				pinchZoomX: false,
				paddingRight: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
			}));

			let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
				behavior: "none"
			}));

			cursor.lineY.set("visible", true);

			const data = this.accountData.transactions.map(item => {
				return {
					dateSelected: new Date(item.dateSelected).getTime(),
					amount: item.amount
				}
			});

			// Ejes
			let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
				maxDeviation: 0.5,
				groupData: true,
				groupCount: 300,
				baseInterval: {timeUnit: "second", count: 1},
				gridIntervals: [
					{timeUnit: "day", count: 1},
					{timeUnit: "month", count: 1}
				],
				renderer: am5xy.AxisRendererX.new(root, {}),

			}));
			let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
				baseValue: -100000,
				renderer: am5xy.AxisRendererY.new(root, {
					pan: "none",
				}),
			}));

			let xRenderer = xAxis.get("renderer");
			xRenderer.grid.template.set("forceHidden", true);
			xRenderer.labels.template.set("paddingTop", 0);
			xRenderer.labels.template.set("forceHidden", true);


			let yRenderer = yAxis.get("renderer");
			yRenderer.grid.template.set("forceHidden", true);
			yRenderer.grid.template.set("strokeOpacity", 0.2);
			yRenderer.grid.template.set("strokeColor", am5.color('#0B3163'));
			yRenderer.grid.template.set("strokeWidth", 1);
			yRenderer.grid.template.set("strokeDasharray", [3, 3]);
			yRenderer.labels.template.set("paddingRight", 0);
			yRenderer.labels.template.set("forceHidden", true);

			// Activa
			let amountSeries = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
				name: "Precio",
				xAxis: xAxis,
				yAxis: yAxis,
				valueYField: "amount",
				valueXField: "dateSelected",
				stroke: am5.color("#369BFA")
			}));

			amountSeries.strokes.template.setAll({
				strokeWidth: 2,
			});
			amountSeries.data.setAll(data);

			amountSeries.fills.template.setAll({
				visible: true,
				fillOpacity: 1,
				fillGradient: am5.LinearGradient.new(root, {
					stops: [{
						opacity: 0.2,
						color: am5.color('#0079FF'),
						offset: 0.3
					}, {
						opacity: 0,
						color: am5.color('#ffffff'),
					}],
					//target: chart.plotContainer,
					rotation: 90
				})
			});

			const time = 200;

			amountSeries.appear(time);

			chart.appear(1000, 100);

			// this.rangeChart.root = root;
			// this.rangeChart.chart = chart;
		},

		// Paginación
		prevPage() {
			if (this.transactionsPagination.currentPage > 1) {
				this.transactionsPagination.currentPage--;
			}
		},
		nextPage() {
			if (this.transactionsPagination.currentPage < this.totalPages) {
				this.transactionsPagination.currentPage++;
			}
		},

		// Ventanas
		toggleWindowStatus(number) {
			this.windowData.section = number;

			this.windowData.show = !this.windowData.show;
			setTimeout(() => {
				if (!this.windowData.show)
					this.resetWindowStatus();
			}, 300)
		},
		toggleWindowForm() {
			this.windowForm.show = !this.windowForm.show;
			// setTimeout(() => {
			// 	if (!this.windowDataBig.show)
			// 		this.resetWindowStatus();
			// }, 300)
		},
		resetWindowStatus() {
			this.windowData.section = '';
			this.transactionEdit = undefined;

		},

		// Filtros
		setDatesFilters() {
			if (this.accountData.transactions.length > 0) {
				this.filters.ranges.from = this.minDate;
				this.filters.ranges.to = this.maxDate;
			}
		},
		checkDatesFilter() {
			this.errors = {};

			let dates = {
				from: moment(this.filters.ranges.from),
				to: moment(this.filters.ranges.to),
			}


			if (dates.from.isAfter(dates.to)) this.errors.toFilter = 'Las fechas no tienen sentido';
		},
		sortList(list) {
			const sortBy = this.filters.sortBy;
			return list.sort((a, b) => {
				switch (sortBy) {
					case "dateSelected":
					case "dateSelected2":
						const dateA = new Date(a.dateSelected);
						const dateB = new Date(b.dateSelected);

						if (sortBy === 'dateSelected') return dateB - dateA;

						return dateA - dateB;


					case "createdAt":
					case "createdAt2":
						const createdAtA = new Date(a.createdAt);
						const createdAtB = new Date(b.createdAt);

						if (sortBy === 'createdAt') return createdAtB - createdAtA;

						return createdAtA - createdAtB;

					case "concept":
					case "category":
						return a[sortBy].localeCompare(b[sortBy]);

					case "amount":
					case "amount2":
						if (sortBy === 'amount') return b.amount - a.amount;
						return a.amount - b.amount;
				}
			});
		},

		// Transacciones
		editTransaction(transaction) {
			this.transactionEdit = transaction;
			this.toggleWindowStatus(1)
		},
		removeTransaction(transaction) {

			if (!this.isLoading) {
				this.isLoading = true;
				Swal.fire({
					title: '¿Estás seguro?',
					icon: 'warning',
					text: !transaction.transferData ? 'El movimiento será borrado y no hay forma de recuperarlo' : 'Al tratarse de una transferencia, el movimientos será eliminado de ambas cuentas.',
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: 'Sí, lo estoy',
					cancelButtonText: 'Mejor no',
					customClass: this.$storage.CUSTOM_CLASS_SWAL,

				}).then((response) => {
					if (response.isConfirmed) {
						axios.delete(`${this.$storage.API_URL}/api/transaction/${this.accountData.info._id}/${transaction._id}`,
								{headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
							this.isLoading = false;

							this.fetchTransactions();
						}).catch((er) => {
							this.isLoading = false;
						})
					} else {
						this.isLoading = false;
					}
				})
			}
		},
		updateTransaction(transaction) {
			if (!this.isLoading) {
				this.isLoading = true;
				let {concept, amount, comment, category, transferData, dateSelected} = transaction;

				let data = {concept, amount, comment, category, transferData, dateSelected};

				axios.put(`${this.$storage.API_URL}/api/transaction/${this.accountData.info._id}/${transaction._id}`,
						data,
						{headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
					this.isLoading = false;
					Swal.fire({
						title: '¡Perfecto!',
						icon: 'success',
						text: 'El movimiento ha sido modificado de forma correcta',
						customClass: this.$storage.CUSTOM_CLASS_SWAL
					})

					this.toggleWindowStatus();

					this.fetchTransactions();
				}).catch((er) => {
					this.isLoading = false;
				})
			}
		},
		importTransactions(table) {
			axios.post(`${this.$storage.API_URL}/api/transaction/${this.$route.params.accountId}/import`, {table: table}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				Swal.fire({
					title: '¡Hecho!',
					icon: 'success',
					html: `Los movimientos han sido importados correctamente`,
					customClass: this.$storage.CUSTOM_CLASS_SWAL
				})

				this.fetchTransactions();
			});
		}
	},
	computed: {
		balance() {
			let transactions = [...this.accountData.transactions];
			if (transactions.length > 0) return transactions.reduce((acc, item) => acc += item.amount, 0);

			return 0;
		},

		transactionsFiltered() {
			let list = [...this.accountData.transactions];

			list = list.filter(item => {
				let string = item.concept + item.category + item.amount;
				let normalizeName = string.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase() + string.toLowerCase();

				let searchMatch = normalizeName.includes(this.filters.searchBy.toLowerCase());

				let dateMatch = true;
				if (this.filters.rangeMode !== 'all') {
					let fromDate = new Date(this.filters.ranges.from).setHours(0, 0, 0);
					let toDate = new Date(this.filters.ranges.to).setHours(23, 59, 59);

					dateMatch = new Date(item.dateSelected) >= fromDate && new Date(item.dateSelected) <= toDate;
				}

				return searchMatch && dateMatch;
			});

			list = this.sortList(list);

			return list;
		},

		startIndex() {
			return (this.transactionsPagination.currentPage - 1) * this.transactionsPagination.itemsPerPage;
		},

		endIndex() {
			return this.startIndex + this.transactionsPagination.itemsPerPage - 1;
		},

		paginatedTransactions() {
			return this.transactionsFiltered.slice(this.startIndex, this.endIndex + 1);
		},

		totalPages() {
			return Math.ceil(this.transactionsFiltered.length / this.transactionsPagination.itemsPerPage);
		},

		minDate() {
			return moment(this.accountData.transactions[0].dateSelected).format('YYYY-MM-DD');
		},

		maxDate() {
			return moment(this.accountData.transactions.slice(-1).dateSelected).format('YYYY-MM-DD');
		}
	},
	mounted() {
		this.fetchAccount();
		this.fetchTransactions(true);
	}
}
</script>

<template>

	<div id="transactions-content" v-if="componentLoaded">
		<div class="window-container" v-bind:class="{opened: windowData.show}">
			<div class="background" v-on:click="toggleWindowStatus"></div>
			<div class="window">
				<div class="window-content" key="0" v-bind:class="{show: windowData.section === 0}">
					<div class="d-flex" data-align-items="center">
						<h3 data-color="azul-oscuro">Filtros de <span data-color="naranja">búsqueda</span></h3>
					</div>

					<div class="form-group mt-20">
						<label for="">Elementos por página</label>
						<div class="input-group">
							<select v-model="transactionsPagination.itemsPerPage">
								<option value="20">20 movimientos</option>
								<option value="40">40 movimientos</option>
								<option value="100">100 movimientos</option>
							</select>
						</div>
					</div>

					<div class="form-group mt-10">
						<label for="">Ordenar por</label>
						<div class="input-group">
							<select v-model="filters.sortBy">
								<option value="concept">Concepto (A-Z)</option>
								<option value="category">Categoría (A-Z)</option>

								<optgroup label="Por fecha de creación">
									<option value="createdAt">Primero recientes</option>
									<option value="createdAt2">Primero antiguos</option>
								</optgroup>

								<optgroup label="Por fecha seleccionada">
									<option value="dateSelected">Primero recientes</option>
									<option value="dateSelected2">Primero antiguos</option>
								</optgroup>

								<optgroup label="Por importe">
									<option value="amount">Mayor a menor</option>
									<option value="amount2">Menor a mayor</option>
								</optgroup>
							</select>
						</div>
					</div>

					<div class="form-group mt-10">
						<label for="">Rango</label>
						<div class="input-group">
							<select v-model="filters.rangeMode">
								<option value="all">Todos los movimientos</option>
								<option value="range">Rango de fechas</option>
							</select>
						</div>
					</div>

					<div v-if="filters.rangeMode === 'range'">
						<div class="separator-horizontal mt-20"></div>
						<div class="form-group mt-10">
							<label for="">Desde</label>
							<div class="input-group">
								<input v-on:change="checkDatesFilter"
									   :min="minDate"
									   :max="maxDate"
									   type="date" v-model="filters.ranges.from">
							</div>
						</div>

						<div class="form-group mt-10"
							 v-bind:class="{'has-error': errors.toFilter}">
							<label for="">Hasta</label>
							<div class="input-group">
								<input v-on:change="checkDatesFilter"
									   v-on:focus="delete errors.toFilter"
									   :min="minDate"
									   :max="maxDate"
									   type="date"
									   v-model="filters.ranges.to">
							</div>
						</div>
					</div>
				</div>

				<div class="window-content" key="1" v-bind:class="{show: windowData.section === 1}">
					<div class="d-flex" data-align-items="center">
						<h3 data-color="azul-oscuro">Editar <span data-color="naranja">movimiento</span></h3>
					</div>

					<transaction-form v-if="transactionEdit"
									  @submitTransaction="updateTransaction"
									  :isEdit="true"
									  :account="accountData.info"
									  :transaction="transactionEdit"
									  class="mt-20"/>
				</div>
			</div>
		</div>

		<div class="window-form-container" v-bind:class="{opened: windowForm.show}">
			<div class="background" v-on:click="toggleWindowForm"></div>
			<div class="window-form">
				<div class="window-header">
					<h3 data-size="20"><i class="fad fa-table-list mr-10" data-color="azul"/>
						Añadir
						<span data-color="naranja">movimientos</span>
						masivamente
					</h3>

					<p class="mt-20" data-weight="300">Optimiza tu tiempo importando varios movimientos bancarios de
						una
						sola vez ya sea mediante un fichero o de forma manual</p>

					<div class="separator-horizontal mt-30"></div>
				</div>
				<div class="window-body d-flex">
					<import-transactions-form :account="accountData.info" @importTransactions="importTransactions"
											  class="grow"/>
				</div>
			</div>
		</div>

		<div class="d-flex column" data-gap-v="40">
			<div id="banner-transactions">
				<div class="h-100 d-flex column">
					<div class="card mb-20 px-40 py-20" data-bg="blanco-puro">
						<div>
							<p data-size="20" data-weight="500">
								<router-link class="mr-10"
											 data-opacity="2"
											 data-opacity-behaviour="hover"
											 :to="{name: 'dashboard'}">
									<i class="far fa-arrow-left"/>
								</router-link>
								Detalles de <span data-color="naranja"
												data-weight="600">{{ accountData.info.name }}</span></p>
						</div>
					</div>

					<div class="mt-auto">
						<div id="banner-info">
							<p data-size="12"
							   data-opacity="5"
							   data-align-text="right"
							   data-weight="300"
							   class="mx-auto uppercase">Balance actual</p>
							<p data-size="30"
							   data-align-text="right"
							   data-weight="700"
							   data-family="montserrat"
							   :data-color="balance > 0 ? 'azul' : 'naranja'"
							   class="mx-auto">
								{{ $utilities.formatNumber(balance, 2, 2) }}€
							</p>
						</div>
					</div>


				</div>

				<div class="d-flex" data-align-items="center">
					<card-cover class="w-100" :account="accountData.info"></card-cover>
				</div>
			</div>

			<div>
				<div id="transactionTitle" class="d-flex" data-align-items="center" data-gap-h="15">
					<h3 class="mr-auto" data-size="20">
						<i data-color="azul"
						   class="fad fa-rectangle-list mr-10"/>
						Todos los <span data-color="naranja">movimientos</span>
					</h3>
					<div class="d-flex searchContainer" data-gap-h="20">
						<div class="d-flex pointer" v-on:click="toggleWindowStatus(0)" data-align-items="center">
							<i class="fad fa-sliders-h"></i>
						</div>

						<div class="form-group">
							<div class="input-group" data-bg="blanco-puro" data-mode="translucido">
								<p class="mr-10" data-opacity="4"><i class="far fa-magnifying-glass"></i></p>
								<input type="text" v-model="filters.searchBy">
							</div>
						</div>
					</div>
				</div>

				<div v-if="transactionsFiltered.length > 0">
					<div class="transactions mt-30">
						<div class="transaction-header">
							<div class="transactionIcon w-20-px">

							</div>
							<div class="transactionConcept ">
								<p data-size="14"
								   data-weight="600">Concepto
								</p>
							</div>
							<div class="transactionCategory ">
								<p data-size="14"
								   data-weight="600" data-align-text="center">Categoría
								</p>
							</div>
							<div class="transactionDate ">
								<p data-size="14"
								   data-weight="600" data-align-text="center">Fecha
								</p>
							</div>
							<div class="transactionAmount ">
								<p data-size="14"
								   data-weight="600"
								   data-align-text="right">Cantidad
								</p>
							</div>
							<div class="transactionOptions ">

							</div>
						</div>
						<transaction-item v-for="transaction in paginatedTransactions"
										  v-bind:key="transaction._id"
										  :account="accountData.info"
										  :transaction="transaction"
										  @editTransaction="editTransaction"
										  @removeTransaction="removeTransaction"/>
					</div>

					<div class="d-flex my-30" data-gap-h="10" data-align-items="center">
						<button v-on:click="transactionsPagination.currentPage = 1"
								class="py-8 px-20 pointer extremeButtons"
								data-size="14"
								data-round="20"
								data-bg="naranja"
								:disabled="transactionsPagination.currentPage === 1">
							<i class="far fa-angles-left"/>
						</button>

						<button v-on:click="prevPage"
								class="py-8 px-20 pointer"
								data-size="14"
								data-round="20"
								data-bg="naranja"
								:disabled="transactionsPagination.currentPage === 1">
							<i class="far fa-long-arrow-left mr-10"/>Anterior
						</button>

						<p class="mx-auto" data-size="14" data-weight="300" data-opacity="4">
							{{ transactionsPagination.currentPage }} de {{ totalPages }}</p>
						<button v-on:click="nextPage"
								class="py-8 px-20 pointer"
								data-size="14"
								data-round="20"
								data-bg="naranja"
								:disabled="transactionsPagination.currentPage === totalPages">
							Siguiente<i class="far fa-long-arrow-right ml-10"/>
						</button>

						<button v-on:click="transactionsPagination.currentPage = totalPages"
								class="py-8 px-20 pointer extremeButtons"
								data-size="14"
								data-round="20"
								data-bg="naranja"
								:disabled="transactionsPagination.currentPage === totalPages">
							<i class="far fa-angles-right"/>
						</button>
					</div>
				</div>

				<div class="mt-50" v-else>
					<p data-weight="300">¡No hay movimientos! Cambia los filtros de búsqueda o añade para poder
						visualizarlos aquí</p>
				</div>

			</div>
		</div>

		<div class="d-flex column"
			 data-gap-v="30">
			<div class="card py-20 px-30"
				 data-bg="blanco-puro">
				<div class="d-flex" data-align-items="center">
					<h3>
						<i class="fad fa-plus-square mr-10"
						   data-color="azul"/>
						Hacer <span data-color="naranja">movimiento
					</span>
					</h3>

					<div v-on:click="toggleWindowForm" class="button py-5 px-15 ml-auto pointer"
						 data-align-text="center"
						 data-bg="naranja"
						 data-mode="borde"
						 data-behaviour="hover"
						 data-hover-mode="translucido">
						<i class="fad fa-table-list"/>
					</div>

				</div>

				<transaction-form @submitTransaction="createTransaction"
								  :account="accountData.info"
								  :transaction="transactionData"
								  class="mt-20"/>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>