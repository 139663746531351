<script>
export default {
	props: ['debt', 'account'],
	data() {
		return {
			debtData: undefined,
			transactions: undefined,

		}
	},
	methods: {
		getDebtData() {
			axios.post(`${this.$storage.API_URL}/api/account/${this.$route.params.accountId}/debt/${this.debt._id}`, this.account, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.debtData = res.data.debt;
				this.transactions = res.data.transactions;
			});
		},
		changeDue(isAdding) {
			if (!this.debtData.isFinished) {
				axios.post(`${this.$storage.API_URL}/api/account/${this.$route.params.accountId}/debt/${this.debt._id}/changeDue`, {},
						{headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}
				).then((res) => {
					this.getDebtData();
					this.$emit('refreshDebts');

				});
			}
		},
		deleteDebt() {
			this.$emit('deleteDebt', this.debtData);
		}
	},
	computed: {
		dues() {
			if (this.debtData.meta.dueMode == 1) return this.debtData.meta.dues;
			return Math.ceil(this.debtData.amount / this.debtData.meta.amountPerDue);
		},

		percent() {
			let current = this.transactions.length;
			console.log(this.debtData.title, `${current}/${this.dues}`);

			return Math.min(Math.trunc(current * 100 / this.dues), 100);

		},

		sum() {
			return this.transactions.reduce((acc, item) => acc + Math.abs(item.amount), 0)
		},

		paymentPlanPerDues() {
			if (!this.debtData) return {fullDues: 0, finalDue: 0};
			const fullDues = Math.floor(this.debtData.amount / this.debtData.meta.amountPerDue);
			const remainingAmount = this.debtData.amount - (fullDues * this.debtData.meta.amountPerDue);

			return {
				fullDues: fullDues,
				finalDue: remainingAmount
			};
		},

	},
	mounted() {
		this.getDebtData();

	}
}
</script>

<template>
	<div class="debt-card" v-if="debtData">
		<div class="debt-card-body">
			<p data-weight="300" data-size="10" data-opacity="4">{{ debtData._id }}</p>
			<div class="d-flex mt-10" data-align-items="center">


				<p data-weight="600" data-size="15">{{ debtData.meta.targetMode == 1 ? 'Personal' : 'Externa' }}</p>

				<p data-opacity="5"
				   class="ml-auto"
				   data-size="11">
					{{ transactions.length }} cuotas / <span data-weight="600">{{ dues }} cuotas</span>
				</p>
			</div>

			<div class="mt-30">
				<p data-weight="500" data-size="20">{{ debtData.title }}</p>
				<div class="progress-bar mt-20">
					<div class="progress-bg" v-if="percent > 0" :style="`width: calc(${percent}% + 10px)`"></div>
					<div class="progress" :style="`width: ${percent}%`"></div>
				</div>
			</div>

			<div class="d-flex mt-5" data-align-items="center">
				<p data-weight="300" data-size="14">{{ $utilities.formatNumber(sum, 2, 2) }}€</p>
				<p class="ml-auto" data-weight="300" data-size="14">{{
						$utilities.formatNumber(debtData.amount, 2, 2)
					}}€</p>

			</div>

			<div class="separator-horizontal my-15"></div>
			<div class="d-flex mt-5" data-align-items="center">
				<p data-weight="600" data-size="14">De: <span data-weight="300"
															  data-color="azul">{{ account.name }}</span>
				</p>
				<p class="ml-auto" data-weight="600" data-size="14">Para: <span data-weight="300"
																				data-color="naranja">{{
						debtData.meta.targetMode == 1 ? debtData.meta.account : debtData.meta.target
					}}</span>
				</p>


			</div>

			<div v-if="debtData.meta.dueMode == 1"
				 class="mt-30 d-flex py-10 px-15" data-round="15" data-gap-h="15" data-align-items="center"
				 data-bg="azul-warning">
				<i data-color="azul" class="fad fa-calculator"></i>

				<p class="mx-auto" data-weight="300" data-size="11" data-align-text="center">
					{{ $utilities.formatNumber(debtData.amount, 2, 2) }}€ / {{ debtData.meta.dues }} cuota(s) =
					<span data-color="azul" data-weight="600">{{
							$utilities.formatNumber(debtData.amount / debtData.meta.dues, 2, 2)
						}}€/cuota</span></p>

			</div>

			<div v-if="debtData.meta.dueMode == 2"
				 class="mt-30 d-flex py-10 px-15" data-round="15" data-gap-h="15" data-align-items="center"
				 data-bg="azul-warning">
				<i data-color="azul" class="fad fa-calculator"></i>

				<p class="mx-auto" data-weight="300" data-size="11" data-align-text="center">
								<span data-color="azul" data-weight="600">
									{{ paymentPlanPerDues.fullDues }} cuotas</span> ×
					{{ $utilities.formatNumber(debtData.meta.amountPerDue, 2, 2) }}€ +
					<span data-color="azul" data-weight="600">{{
							$utilities.formatNumber(paymentPlanPerDues.finalDue, 2, 2)
						}}€</span> =
					{{ $utilities.formatNumber(debtData.amount, 2, 2) }}€</p>

			</div>
		</div>

		<div class="debt-card-footer">
			<div class="d-flex" data-align-items="center" data-gap-h="10">


				<button data-bg="blanco-sucio"
						v-on:click="deleteDebt"
						data-mode="borde"
						data-round="15"
						class="py-10 px-15 mr-auto pointer"
						data-color="contraste">
					<i class="far fa-trash"/>
				</button>

				<button
						:data-opacity="sum >= debtData.amount ? 2 : 10"
						data-bg="blanco-sucio"
						v-on:click="changeDue"
						data-mode="borde"
						data-round="15"
						class="py-10 px-15 pointer"
						data-color="contraste">Añadir cuota
				</button>

			</div>
		</div>
	</div>
</template>

<style scoped>
</style>