<script>

export default {
	props: ['account', 'transaction', 'accounts', 'isEdit'],
	data() {
		return {
			componentLoaded: false,
			errors: {},
			accounts: [],
			transactionData: {
				concept: '',
				account: '',
				amount: '',
				comment: '',
				dateSelected: moment().format('YYYY/MM/DD HH:mm:ss'),
				category: '',
			}

		}
	},
	methods: {
		selectCategory(category) {
			this.transactionData.category = category;
		},
		newCategory(category) {
			axios.post(`${this.$storage.API_URL}/api/account/${this.account._id}/category`, {category}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.account.categories = res.data.updatedAccount.categories;
				setTimeout(() => {
					this.transactionData.category = this.account.categories.find(item => item.name === category)._id;
				}, 200);
			}).catch((er) => {

			})
		},
		fetchAccounts() {
			axios.post(`${this.$storage.API_URL}/api/account`, {}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.accounts = res.data.accounts;
				this.componentLoaded = true;
			})
		},

		submitTransaction() {
			let {concept, amount, category, comment, dateSelected} = this.transactionData;
			this.errors = {};

			amount = parseFloat(amount);
			this.errors.concept = !concept.trim() ? 'El concepto es obligatorio' : undefined;
			this.errors.amount = isNaN(amount) ? 'La cantidad debe ser un número' : undefined;
			this.errors.dateSelected = !dateSelected.trim() ? 'La fecha es obligatoria' : undefined;
			this.errors.comment = comment.length > this.$storage.LIMIT_CHARS_COMMENT ? 'Demasiados caracteres' : undefined;

			if (!Object.values(this.errors).some(error => error !== undefined)) {
				this.$emit('submitTransaction', this.transactionData);
				this.setTransaction();
			}
		},
		selectAccount(option) {
			this.transactionData.account = option._id;
		},
		setTransaction() {
			let {_id, concept, account, amount, comment, dateSelected, category} = this.transaction;

			if (!account || account === '') account = this.account._id;
			let momentDate = moment(dateSelected);
			dateSelected = momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

			this.transactionData = {_id, concept, account, amount, comment, dateSelected, category};
		}

	},
	mounted() {
		this.fetchAccounts();
		this.setTransaction();
	},
	computed: {
		categories() {
			return this.account.categories.map(item => {
				return {
					title: item.name,
					value: item._id
				}
			});
		},

	}
}
</script>

<template>
	<div class="d-flex column" data-gap-v="20" v-if="componentLoaded">

		<!-- Cuenta destino -->
		<div class="form-group" v-on:click="delete errors.account"
			 v-if="!isEdit"
			 v-bind:class="{'has-error': errors.account}">
			<label for="">Cuenta destino</label>
			<account-selection
					class="mt-10"
					:selected="transactionData.account"
					:accounts="accounts"
					@selectOption="selectAccount"/>
		</div>

		<!-- Concepto -->
		<div class="form-group" v-bind:class="{'has-error': errors.concept}">
			<label for="concept">Concepto</label>
			<div class="input-group">
				<input v-on:focus="delete errors.concept"
					   v-model="transactionData.concept"
					   id="concept"
					   type="text"
					   placeholder="">
			</div>
		</div>

		<!-- Categoría -->
		<div class="form-group">
			<label for="">Categoría</label>
			<custom-selection
					:admitNew="true"
					:selected="transactionData.category"
					:options="categories"
					@addNew="newCategory"
					@selectOption="selectCategory"/>
		</div>

		<div class="d-grid" data-columns="2" data-gap-h="20">
			<!-- Cantidad -->
			<div class="form-group" v-bind:class="{'has-error': errors.amount}">
				<label for="amount">Cantidad</label>
				<div class="input-group">
					<input v-on:focus="delete errors.amount" v-model="transactionData.amount" id="amount" type="text"
						   placeholder="">
				</div>
			</div>

			<!-- Fecha -->
			<div class="form-group" v-bind:class="{'has-error': errors.dateSelected}">
				<label for="amount">Fecha</label>
				<div class="input-group">
					<input v-on:focus="delete errors.dateSelected" v-model="transactionData.dateSelected"
						   id="dateSelected"
						   type="date"
						   placeholder="">
				</div>
			</div>
		</div>

		<!-- Comentario -->
		<div class="form-group" v-bind:class="{'has-error': errors.comment}">
			<div class="d-flex" data-align-items="center">
				<label for="amount">Comentario</label>
				<p data-size="10"
				   :data-color="transactionData.comment.length > $storage.LIMIT_CHARS_COMMENT ? 'naranja' : ''"
				   :data-opacity="transactionData.comment.length > $storage.LIMIT_CHARS_COMMENT ? 10 : 4"
				   data-weight="300" class="ml-auto">
					{{ transactionData.comment.length }}
					/
					{{ $storage.LIMIT_CHARS_COMMENT }}
				</p>
			</div>
			<div class="input-group">
				<textarea v-on:focus="delete errors.comment" v-model="transactionData.comment"/>
			</div>
		</div>

		<button type="button"
				v-on:click="submitTransaction"
				class="w-100 button mt-10"
				data-bg="azul"
				data-mode="borde"
				data-behaviour="hover"
				data-hover-mode="translucido">
			¡Guardar datos!
		</button>
	</div>
</template>

<style scoped>
</style>