<script>
export default {
	props: ['category', 'transactions', 'limits'],
	data() {
		return {}
	},
	methods: {},
	computed: {
		userData() {
			return JSON.parse(localStorage.getItem('userData'));
		},

		amount() {
			return this.transactions.reduce((acc, transaction) => {
				return transaction.category === this.category._id ? acc + transaction.amount * -1 : acc;
			}, 0);
		},

		width() {
			if (!this.category.limit) return 50;
			return Math.max(Math.min(this.amount * 100 / this.category.limit, 100), 0);
		},
	},
	mounted() {
	}
}
</script>

<template>
	<div class="d-flex mt-15"
		 data-align-items="center"
		 data-gap-h="20">
		<div class="w-30-px h-30-px d-flex"
			 data-align-items="center"
			 data-justify-content="center"
			 data-bg="morado"
			 data-mode="translucido"
			 data-round="10">
			<span class="uppercase" data-size="14">{{ category.name.charAt(0) }}</span>
		</div>

		<div class="grow">
			<div class="d-flex mb-5"
				 data-align-items="center">
				<p class="first-uppercase"
						data-weight="300"
				   data-size="13">{{ category.name }}</p>
				<p class="ml-auto"
				   data-weight="400"
				   data-family="montserrat"
				   data-size="13">
					<span data-weight="600" :data-color="category.limit && amount > category.limit ? 'naranja' : amount === 0 || !category.limit ? '' : amount < 0 ? 'verde' : 'azul'">{{ $utilities.formatNumber(amount, 2, 2) }}€</span>
					<span data-opacity="4"> / {{ category.limit ? `${ $utilities.formatNumber(category.limit, 2, 2) }€` : 'Sin límite' }}</span></p>
			</div>
			<!-- Si tiene límite y está por debajo de cero -->
			<div v-if="category.limit && amount < 0" class="d-flex h-10-px"
				 data-round="5"
				 data-justify-content="flex-end"
				 data-bg="blanco-claro">

				<div class="d-flex h-10-px mt-1 w-10 mr--1" data-spacing="negativo"
					 data-round="5"
					 data-mode="translucido"

					 data-bg="verde" data-opacity="8">


				</div>
			</div>

			<!-- Si tiene límite y está por encima de cero -->
			<div v-if="category.limit && amount >= 0" class="d-flex h-10-px"
				 data-round="5"
				 data-bg="blanco-claro">


				<div class="d-flex h-10-px mt-1" v-if="width > 0" data-spacing="negativo" :style="`width: ${width}%`"
					 data-round="5"
					 data-mode="translucido"
					 :data-bg="!category.limit ? 'gris' : amount <= category.limit ? 'azul' : 'naranja'">

				</div>
			</div>

			<!-- Si no tiene límite -->
			<div v-if="!category.limit" class="d-flex h-10-px"
				 data-opacity="3"
				 data-round="5"
				 data-bg="blanco">

			</div>

		</div>
	</div>
</template>

<style scoped>
</style>