<script>
export default {
	props: ['userData'],
	data() {
		return {
			openAside: false
		}
	},
	methods: {},
	computed: {}
}
</script>

<template>
	<div class="navigation">
		<nav data-media="big">
			<div class="logo">
			</div>

			<div v-if="!$utilities.isLoggedIn()" class="d-flex" data-align-items="center">
				<div class="nav-links mx-auto">
					<router-link data-color="azul-oscuro" class="nav-link" :to="{name: 'home'}">Inicio</router-link>
					<router-link data-color="azul-oscuro" class="nav-link" :to="{name: 'services'}">Servicios
					</router-link>
				</div>

				<router-link :to="{name:'area-client'}" class="button" data-bg="azul-oscuro"
							 :data-mode="$route.path === '/area-client' ? '' : 'borde'" data-behaviour="hover"
							 data-weight="300">Portal de clientes
				</router-link>
			</div>

			<div v-else class="d-flex" data-align-items="center">



				<router-link :to="{ name: 'profile' }" class="profile-section pointer ml-auto">
					<div class="profile-image">
						<img :src="`/assets/profile_images/${userData.profileImage ?? 'default.jpg'}`" alt="">
					</div>

					<div>
						<p data-size="" data-weight="600">{{ userData.name }}</p>
						<p data-size="10" data-weight="300">{{ userData.email }}</p>
					</div>
				</router-link>
			</div>
		</nav>

		<nav data-media="small">
			<div class="logo">
			</div>


			<div class="d-flex" data-align-items="center">
				<div class="toggle-button ml-auto pointer" v-on:click="openAside = true">
					<i class="fas fa-bars"></i>
				</div>
			</div>

			<aside v-bind:class="{showed: openAside}">
				<div class="w-100 d-flex" data-align-items="center">
					<img class="h-30-px" src="/assets/logos/logofullcontraste.png" alt="">
					<p v-on:click="openAside = false" class="ml-auto" data-size="30" data-color="blanco-puro">
						<i class="far fa-xmark"/>
					</p>
				</div>

				<div class="separator-horizontal my-20" data-bg="blanco-puro"></div>
				<div class="d-flex column" v-if="!$utilities.isLoggedIn()">
					<router-link v-on:click="openAside = false" class="aside-item" :to="{name: 'home'}">Inicio</router-link>
					<router-link v-on:click="openAside = false" class="aside-item" :to="{name: 'services'}">Servicios</router-link>
					<router-link v-on:click="openAside = false" class="aside-item" :to="{name:'area-client'}">Portal de clientes</router-link>
				</div>

				<div class="d-flex column" v-else>


					<router-link v-on:click="openAside = false" class="aside-item"
								 :to="{name: 'profile'}">Mi perfil
					</router-link>
				</div>
			</aside>
		</nav>
	</div>
</template>

<style scoped>
</style>
