<script>
export default {
	props: [],
	data() {
		return {}
	},
	methods: {},
	computed: {}
}
</script>

<template>
	<footer data-bg="azul-oscuro">
		<div class="d-grid info" data-columns="3">
			<div class="d-flex" data-align-items="center">
				<img class="h-30-px" src="/assets/logos/logoblancofull.png" alt="">
			</div>

			<div></div>
			<div></div>
		</div>

		<div class="mt-auto h-50-px d-flex" data-align-items="center" data-justify-content="center" data-bg="azul-oscurisimo">
			<p data-weight="300" data-size="14" data-opacity="4" data-color="blanco-puro" data-align-text="center">Creado por Jos © {{ new Date().getFullYear()}}</p>
		</div>
	</footer>
</template>

<style scoped>
</style>