<script>
export default {
	props: ['selected', 'options', 'admitNew'],
	data() {
		return {
			isOpen: false,
			searchBy: '',
		}
	},
	methods: {
		selectOption(value) {
			this.searchBy = '';
			this.isOpen = false;
			this.$emit('selectOption', value);
		},

		addNew() {
			if (this.searchBy.trim() === '') return;

			this.isOpen = false;
			this.$emit('addNew', this.searchBy);
			this.searchBy = '';

		}
	},
	computed: {
		listFiltered() {
			return this.options.filter(item => {
				let normalizeName = item.title.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase() + item.title.toLowerCase();
				return normalizeName.includes(this.searchBy.toLowerCase());
			})
		},
		selectedOption() {
			return this.options.find(item => item.value === this.selected);
		}
	}
}
</script>

<template>
	<div>
		<div class="custom-select" v-on:mouseleave="isOpen = false">
			<div class="preview" data-bg="blanco-puro" v-on:click="isOpen = !isOpen">
				<p class="first-uppercase" v-if="selected !== ''">{{ selectedOption.title }}</p>
				<p data-opacity="4" v-else>Selecciona una opción</p>
				<i class="selector-icon ml-auto far fa-angle-down"></i>
			</div>

			<div class="options" v-bind:class="{ show: isOpen }">
				<div class="option-container">
					<div class="input-group mt-0">
						<input type="text" v-model="searchBy" placeholder="Buscar o añadir">
					</div>

					<div class="separator-horizontal my-10"></div>

					<div class="options-group">
						<div class="option-item"
							 v-bind:class="{'selectedOption': option.value === selected}"
							 v-for="option in listFiltered"
							 v-on:click="selectOption(option.value)">
							{{ $utilities.capitalizeString(option.title) }}
						</div>
					</div>



					<div v-if="listFiltered.length === 0 && admitNew"
						 class="d-flex pointer py-10 px-20"
						 data-round="20"
						 data-align-items="center"
						 data-justify-content="center"
						 data-bg="naranja"
						 data-mode="borde"
						 data-hover-mode="translucido"
						 data-behaviour="hover"
						 v-on:click="addNew">
						<i data-color="naranja"
						   data-size="14"
						   class="fas fa-plus"/>
						<p data-size="14"
						   class="ml-10">
							Añadir nuevo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>