<script>
export default {
	props: [],
	data() {
		return {
			register: {
				email: '',
				name: '',
				phone: '',
				password: '',
				passwordConfirm: '',
				gender: 'M',
			},
			login: {
				email: '',
				password: '',
			},


			loginShowPassword: false,
			registerShowPassword: false,
			registerShowConfirmPassword: false,
			changeShowPassword: false,
			changeShowConfirmPassword: false,

			isLoading: false,
			errors: {},

			// Ventana
			windowData: {
				show: false,
				section: 0,
			},

			// Sección de recuperación
			recoverPasswordData: {
				showForm: false,
				email: '',
				code: '',
				password: '',
				passwordConfirm: '',
			},

			// Sección de verificación
			verificationData: {
				showForm: false,
				email: '',
				code: ''
			}


		}
	},
	methods: {
		registerSubmit() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {name, email, phone, password, passwordConfirm} = this.register;
				this.errors = {};

				this.errors.nameRegister = !name.trim() ? 'El nombre es obligatorio' : undefined;
				this.errors.emailRegister = !email.trim() ? 'El correo electrónico es obligatorio' : !this.$utilities.isValidEmail(email) ? 'El correo electrónico no es válido' : undefined;
				this.errors.phoneRegister = !phone.trim() ? 'El teléfono es obligatorio' : !this.$utilities.isValidPhone(phone) ? 'El teléfono no es válido' : undefined;
				this.errors.passwordRegister = !password.trim() ? 'La contraseña es obligatoria' : !this.$utilities.isValidPassword(password) ? 'La contraseña no cumple con los requisitos' : undefined;
				this.errors.passwordConfirmRegister = !passwordConfirm.trim() ? 'La confirmación de la contraseña es obligatoria' : password !== passwordConfirm ? 'Las contraseñas no coinciden' : undefined;

				if (!Object.values(this.errors).some(error => error !== undefined))
					axios.post(`${this.$storage.API_URL}/api/user/register`, this.register).then((res) => {
						Swal.fire({
							title: '¡Hecho!',
							icon: 'success',
							text: 'No olvides que antes de iniciar sesión debes de verificar tu correo electrónico siguiendo las instrucciones que recibirás',
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						})

						this.isLoading = false;
					}).catch((er) => {
						// this.errors = er.response.data.errors;
						this.isLoading = false;
					})
				else
					this.isLoading = false;
			}
		},
		loginSubmit() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {email, password} = this.login;
				this.errors = {};


				this.errors.emailLogin = !email.trim() ? 'El correo electrónico es obligatorio' : undefined;
				this.errors.passwordLogin = !password.trim() ? 'La contraseña es obligatoria' : undefined;


				if (!Object.values(this.errors).some(error => error !== undefined))
					axios.post(`${this.$storage.API_URL}/api/auth/login`, this.login).then((res) => {
						this.isLoading = false;
						localStorage.setItem('sessionToken', res.data.token);
						localStorage.setItem('userData', JSON.stringify(res.data.user));
						window.location = '/dashboard';


					}).catch((er) => {
						console.log(er);
						this.errors = er.response.data.errors;
						this.isLoading = false;
					})
				else
					this.isLoading = false;
			}
		},

		// Enviar código de recuperación de contraseña
		sendCodePasswordRecover() {
			if (!this.isLoading) {
				this.isLoading = true;
				this.errors = {};

				const {email} = this.recoverPasswordData;

				this.errors.recoverPasswordDataEmail = !email.trim() ? 'El correo electrónico es obligatorio' : !this.$utilities.isValidEmail(email) ? 'El correo electrónico no es válido' : undefined;

				if (!Object.values(this.errors).some(error => error !== undefined))
					axios.post(`${this.$storage.API_URL}/api/auth/send-code-password-recover`, {email}).then(res => {
						Swal.fire({
							title: 'Revisa tu correo',
							icon: 'success',
							text: res.data.message,
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						}).then(() => {
							this.recoverPasswordData.showForm = true;
						})
						this.isLoading = false;


					}).catch(err => {
						this.errors = err.response.data.errors;
						this.isLoading = false;
					})
				else
					this.isLoading = false;
			}
		},
		recoverPassword() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {code, password, passwordConfirm} = this.recoverPasswordData;
				this.errors = {};


				this.errors.recoverPasswordCode = !code.trim() ? 'El código es obligatorio' : undefined;
				this.errors.recoverPasswordPassword = !password.trim() ? 'La contraseña es obligatoria' : !this.$utilities.isValidPassword(password) ? 'La contraseña no cumple con los requisitos' : undefined;
				this.errors.recoverPasswordConfirmPassword = !passwordConfirm.trim() ? 'La confirmación de la contraseña es obligatoria' : password !== passwordConfirm ? 'Las contraseñas no coinciden' : undefined;

				if (!Object.values(this.errors).some(error => error !== undefined)) {

					axios.post(`${this.$storage.API_URL}/api/user/recover-password`, {code, password}).then((res) => {
						Swal.fire({
							title: '¡Hecho!',
							icon: 'success',
							text: 'Intenta acceder ahora a la aplicación con tu nueva contraseña',
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						}).then(() => {
							this.showWindow = false;
							this.resetWindowStatus();
						})

						this.isLoading = false;
					}).catch((er) => {
						this.errors = er.response.data.errors;
						this.isLoading = false;
					})
				} else
					this.isLoading = false;
			}
		},

		// Reenviar verificación
		resendVerification() {
			if (!this.isLoading) {
				this.isLoading = true;
				this.errors = {};

				const {email} = this.verificationData;

				this.errors.verificationDataEmail = !email.trim() ? 'El correo electrónico es obligatorio' : !this.$utilities.isValidEmail(email) ? 'El correo electrónico no es válido' : undefined;

				if (!Object.values(this.errors).some(error => error !== undefined))
					axios.post(`${this.$storage.API_URL}/api/auth/send-code-verification`, {email}).then(res => {
						Swal.fire({
							title: 'Revisa tu correo',
							icon: 'success',
							text: res.data.message,
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						}).then(() => {
							this.recoverPasswordData.showForm = true;
							this.verificationData.showForm = true;
						})
						this.isLoading = false;


					}).catch(err => {
						this.errors = err.response.data.errors;
					})
				else
					this.isLoading = false;
			}

		},
		verificateAccount() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {code} = this.verificationData;
				this.errors = {};

				this.errors.verificationDataCode = !code.trim() ? 'El código es obligatorio' : undefined;

				if (!Object.values(this.errors).some(error => error !== undefined)) {

					axios.post(`${this.$storage.API_URL}/api/user/verify`, {code}).then((res) => {
						Swal.fire({
							title: '¡Cuenta verificada!',
							icon: 'success',
							text: 'Ahora puedes entrar a la aplicación y disfrutar de todas las funcionalidades que ofrecemos',
							customClass: this.$storage.CUSTOM_CLASS_SWAL
						}).then(() => {
							this.showWindow = false;
							this.resetWindowStatus();
						})

						this.isLoading = false;
					}).catch((er) => {
						this.errors = er.response.data.errors;
						this.isLoading = false;
					})
				} else
					this.isLoading = false;
			}
		},

		toggleWindowStatus() {
			this.windowData.show = !this.windowData.show;
			setTimeout(() => {
				this.resetWindowStatus();

			}, 300)
		},
		changeWindowContent(number) {
			this.windowData.section = '';
			setTimeout(() => {
				this.windowData.section = number;
			}, 200)
		},
		resetWindowStatus() {
			// Ventana
			this.windowData.section = 0;

			// Sección de recuperación
			this.recoverPasswordData = {
				showForm: false,
				email: '',
				code: '',
				password: '',
				passwordConfirm: '',
			};

		}
	},
	mounted() {
		let query = {...this.$route.query};
		Object.keys(query).forEach(key => {
			const value = query[key];

			switch (key) {
				case 'sw':
					this.windowData.show = !!value;
					break;
				case 'ws':
					this.windowData.section = value * 1;
					break;
				case 'rpsf':
					this.recoverPasswordData.showForm = !!value;
					break;
				case 'vsf':
					this.verificationData.showForm = !!value;
					break;
				case 'rpc':
					this.recoverPasswordData.code = value;
					break;
				case 'vc':
					this.verificationData.code = value;
					break;
			}
		})
	},
	computed: {}
}
</script>

<template>
	<div class="container">
		<div class="window-container" v-bind:class="{opened: windowData.show}">
			<div class="background" v-on:click="toggleWindowStatus"></div>
			<div class="window">
				<div class="window-content"
					 key="0"
					 v-bind:class="{show: windowData.section === 0}">

					<h3 data-color="azul-oscuro">Vamos a resolver tu
						<span data-color="naranja">problema</span>
					</h3>

					<p data-color="contraste" class="my-30" data-weight="300" data-size="14">Elige la situación en la
						que te encuentres para poder ayudarte</p>

					<div class="d-flex px-20 py-20 pointer"
						 data-round="10"
						 data-bg="blanco-sucio"
						 data-mode="borde"
						 data-align-items="center"
						 v-on:click="changeWindowContent(1)">
						<p><i data-color="naranja" class="far fa-key mr-10"></i> No recuerdo mi
							contraseña</p>
						<i class="ml-auto far fa-angle-right" data-color="azul-oscuro"></i>
					</div>

					<div class="d-flex px-20 mt-10 py-20 pointer"
						 data-round="10"
						 data-bg="blanco-sucio"
						 data-mode="borde"
						 data-align-items="center"
						 v-on:click="changeWindowContent(2)">
						<p><i data-color="azul" class="far fa-envelope mr-10"></i> No puedo
							verificar mi cuenta</p>
						<i class="ml-auto far fa-angle-right" data-color="azul-oscuro"></i>
					</div>

					<p v-on:click="toggleWindowStatus" class="underline pointer mt-40" data-align-text="center"
					   data-opacity="5" data-weight="300" data-size="14" data-opacity-behaviour="hover">No necesito
						nada</p>
				</div>

				<!-- Recuperar contraseña -->
				<div class="window-content"
					 key="1"
					 v-bind:class="{show: windowData.section === 1}">
					<h3 class=""
						data-color="azul-oscuro">
						<i v-on:click="changeWindowContent(0)"
						   class="far fa-long-arrow-left mr-10">
						</i>
						Recuperemos tu
						<span data-color="naranja">contraseña</span>
					</h3>

					<!-- Enviar código -->
					<div v-if="recoverPasswordData.showForm" class="d-flex column h-100">
						<p data-color="contraste" class="my-30" data-weight="300" data-size="14">Mete el código e
							introduce la contraseña que quieras, pero si no tienes el código <span class="underline"
																								   v-on:click="recoverPasswordData.showForm = false">pulsa aquí para solicitarlo</span>.
						</p>

						<div class="form-group" v-bind:class="{'has-error': errors.recoverPasswordCode}">
							<label for="recovery-password-code">Código</label>
							<div class="input-group">
								<input id="recovery-password-code" v-on:focus="delete errors.recoverPasswordCode"
									   type="text"
									   v-model="recoverPasswordData.code">
							</div>
						</div>
						<div class="form-group mt-10" v-bind:class="{'has-error': errors.recoverPasswordPassword}">
							<label for="recovery-new-password">Nueva contraseña</label>
							<div class="input-group">
								<input id="recovery-new-password" v-on:focus="delete errors.recoverPasswordPassword"
									   :type="changeShowPassword?'text':'password'"
									   v-model="recoverPasswordData.password">
								<i class="ml-10 fa-thin fa-eye pointer" v-on:mouseenter="changeShowPassword = true"
								   v-on:mouseleave="changeShowPassword = false"></i>
							</div>
						</div>
						<div class="form-group mt-10"
							 v-bind:class="{'has-error': errors.recoverPasswordConfirmPassword}">
							<label for="recovery-new-password-confirm">Confirma contraseña</label>
							<div class="input-group">
								<input id="recovery-new-password-confirm"
									   v-on:focus="delete errors.recoverPasswordConfirmPassword"
									   :type="changeShowConfirmPassword?'text':'password'"
									   v-model="recoverPasswordData.passwordConfirm">
								<i class="ml-10 fa-thin fa-eye pointer"
								   v-on:mouseenter="changeShowConfirmPassword = true"
								   v-on:mouseleave="changeShowConfirmPassword = false"></i>
							</div>
						</div>
						<button v-on:click="recoverPassword"
								:disabled="isLoading"
								class="w-100 button mt-30"
								data-bg="naranja"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">
							{{ isLoading ? 'Un segundo...' : 'Cambiar contraseña' }}

						</button>
					</div>
					<form v-on:submit.prevent="sendCodePasswordRecover"
						  class="d-flex column h-100"
						  v-else>

						<p data-color="contraste"
						   class="mt-30"
						   data-weight="300"
						   data-size="14">Introduce tu correo para enviarte el código de recuperación.</p>

						<p data-color="contraste"
						   class="mb-30 mt-10"
						   data-weight="300"
						   data-size="14">Si ya tienes tu código,
							<span class="underline" v-on:click="recoverPasswordData.showForm = true">pulsa aquí</span>
							para recuperar la contraseña directamente.</p>


						<div class="form-group"
							 v-bind:class="{'has-error': errors.recoverPasswordDataEmail}">
							<label for="recover-email">Correo</label>
							<div class="input-group">
								<input id="recover-email"
									   v-on:focus="delete errors.recoverPasswordDataEmail"
									   type="text"
									   v-model="recoverPasswordData.email">
							</div>
						</div>

						<button type="submit"
								:disabled="isLoading"
								class="w-100 button mt-30"
								data-bg="azul"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">
							{{ isLoading ? 'Un segundo...' : 'Obtener código' }}
						</button>
					</form>

					<!-- Cambiar contraseña -->


				</div>

				<!-- Verificar -->
				<div class="window-content" key="2" v-bind:class="{show: windowData.section === 2}">
					<h3 class="" data-color="azul-oscuro">
						<i v-on:click="changeWindowContent(0)"
						   class="far fa-long-arrow-left mr-10">
						</i>
						Verifiquemos tu
						<span data-color="azul">cuenta</span>
					</h3>

					<!-- Reenviar correo -->
					<form v-on:submit.prevent="verificateAccount" v-if="verificationData.showForm"
						  class="d-flex column h-100">
						<p data-color="contraste"
						   class="my-30"
						   data-weight="300"
						   data-size="14">
							Mete el código y terminaremos de verificar tu cuenta. Si aún no lo tienes,
							<span class="underline"
								  v-on:click="verificationData.showForm = false">
								pulsa aquí para solicitarlo
							</span>.
						</p>

						<div class="form-group" v-bind:class="{'has-error': errors.verificationDataCode}">
							<label for="verification-code">Código</label>
							<div class="input-group">
								<input id="verification-code"
									   v-on:focus="delete errors.verificationDataCode"
									   type="text"
									   v-model="verificationData.code">
							</div>
						</div>

						<button type="submit"
								class="w-100 button mt-30"
								data-bg="naranja"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">
							{{ isLoading ? 'Un segundo...' : 'Verificar cuenta' }}
						</button>
					</form>
					<form v-on:submit.prevent="resendVerification" v-else class="d-flex column h-100">

						<p data-color="contraste" class="mt-30" data-weight="300" data-size="14">Introduce tu correo
							para enviarte el código de verificación.</p>
						<p data-color="contraste" class="mb-30 mt-10" data-weight="300" data-size="14">Si ya tienes tu
							código, <span class="underline"
										  v-on:click="verificationData.showForm = true">pulsa aquí</span> para
							recuperar la contraseña directamente.</p>


						<div class="form-group" v-bind:class="{'has-error': errors.verificationDataEmail}">
							<label for="email-register">Correo</label>
							<div class="input-group">
								<input id="email-register" v-on:focus="delete errors.verificationDataEmail"
									   type="text"
									   v-model="verificationData.email">
							</div>
						</div>

						<button
								:disabled="isLoading"
								class="w-100 button mt-30"
								data-bg="azul"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">{{ isLoading ? 'Un segundo...' : 'Obtener código' }}
						</button>
					</form>

					<!-- Verificar cuenta -->

				</div>
			</div>
		</div>

		<div class="client-area">


			<form class="card" id="register-card" v-on:submit.prevent="registerSubmit">

				<h3 class="mb-10" data-line-height="25" data-size="30">¿Aún no tienes cuenta?<br><span
						data-color="naranja">Regístrate</span> ya :)</h3>

				<div class="d-grid responsive" data-columns="2" data-gap-h="20">
					<div class="form-group" v-bind:class="{'has-error': errors.nameRegister}">
						<label for="name-register">Nombre</label>
						<div class="input-group">
							<input id="name-register" v-on:focus="delete errors.nameRegister" type="text"
								   v-model="register.name">
						</div>
					</div>

					<div class="form-group" v-bind:class="{'has-error': errors.genderRegister}">
						<label for="gender-register">Género</label>
						<div class="input-group">
							<select id="gender-register" v-model="register.gender">
								<option value="M">Masculino</option>
								<option value="F">Femenino</option>
								<option value="O">No especificar</option>
							</select>
						</div>
					</div>
				</div>

				<div class="d-grid responsive" data-columns="2" data-gap-h="20">
					<div class="form-group" v-bind:class="{'has-error': errors.emailRegister}">
						<label for="email-register">Correo</label>
						<div class="input-group">
							<input id="email-register" v-on:focus="delete errors.emailRegister" type="text"
								   v-model="register.email">
						</div>
					</div>
					<div class="form-group" v-bind:class="{'has-error': errors.phoneRegister}">
						<label for="phone-register">Teléfono</label>
						<div class="input-group">
							<input id="phone-register" v-on:focus="delete errors.phoneRegister" type="text"
								   v-model="register.phone">
						</div>
					</div>
				</div>

				<div class="d-grid responsive" data-columns="2" data-gap-h="20">
					<div class="form-group" v-bind:class="{'has-error': errors.passwordRegister}">
						<label for="password-register">Contraseña</label>
						<div class="input-group">
							<input id="password-register" v-on:focus="delete errors.passwordRegister"
								   :type="registerShowPassword?'text':'password'" v-model="register.password">
							<i class="ml-10 fa-thin fa-eye pointer" v-on:mouseenter="registerShowPassword = true"
							   v-on:mouseleave="registerShowPassword = false"></i>
						</div>
					</div>
					<div class="form-group" v-bind:class="{'has-error': errors.passwordConfirmRegister}">
						<label for="password-confirm-register">Confirma contraseña</label>
						<div class="input-group">
							<input id="password-confirm-register" v-on:focus="delete errors.passwordConfirmRegister"
								   :type="registerShowConfirmPassword?'text':'password'"
								   v-model="register.passwordConfirm">
							<i class="ml-10 fa-thin fa-eye pointer"
							   v-on:mouseenter="registerShowConfirmPassword = true"
							   v-on:mouseleave="registerShowConfirmPassword = false"></i>
						</div>
					</div>
				</div>
				<p data-opacity="5" data-size="14" data-weight="300">Tu contraseña debe tener como mínimo 8
					caracteres,
					una mayúscula, una minúscula y un número</p>

				<button :disabled="isLoading" data-mode="borde" data-behaviour="hover" type="submit"
						class="button w-100 mt-40 pointer" data-size="20" data-bg="naranja">Registrarme
				</button>
			</form>

			<div class="d-flex column" id="separator" data-align-items="center">
				<div class="separator-vertical"></div>

				<div class="my-15" data-color="azul-oscuro" data-opacity="4" data-weight="300">
					<i v-if="isLoading" class="fas fa-spinner-third fa-spin" data-color="azul-oscuro"></i>
					<p v-else>o</p>
				</div>
				<div class="separator-vertical"></div>
			</div>

			<form class="card" id="login-card" v-on:submit.prevent="loginSubmit">
				<h3 class="mb-10" data-line-height="25" data-size="30">¿Ya tienes cuenta?<br>Pues <span
						data-color="azul">inicia sesión</span> (:</h3>

				<div class="form-group" v-bind:class="{'has-error': errors.emailLogin}">
					<label for="email-login">Correo</label>
					<div class="input-group">
						<input id="email-register" v-on:focus="delete errors.emailLogin" type="text"
							   v-model="login.email">
					</div>
				</div>
				<div class="form-group" v-bind:class="{'has-error': errors.passwordLogin}">
					<label for="password-login">Contraseña</label>
					<div class="input-group">
						<input id="password-login" v-on:focus="delete errors.passwordLogin"
							   :type="loginShowPassword?'text':'password'"
							   v-model="login.password">
						<i class="ml-10 fa-thin fa-eye pointer" v-on:mouseenter="loginShowPassword = true"
						   v-on:mouseleave="loginShowPassword = false"></i>
					</div>
				</div>

				<p v-on:click="toggleWindowStatus" class="mt-auto underline pointer" data-opacity-behaviour="hover"
				   data-size="14" data-opacity="3" data-align-text="center">¿Dificultades en el acceso? Vamos a
					verlo</p>
				<button :disabled="isLoading" type="submit" class="button w-100 pointer" data-size="20"
						data-bg="azul">
					Iniciar sesión
				</button>
			</form>
		</div>
	</div>

</template>

<style scoped>
</style>