<script>
export default {
	props: [],
	data() {
		return {
			userData: {}
		}
	},
	methods: {
		getUser() {
			if (localStorage.key('sessionToken'))
				axios.get(`${this.$storage.API_URL}/api/user/get-user`, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
					this.userData = res.data.user;
				})
		},

		refreshUser() {
			this.getUser();
		}
	},
	mounted() {
		this.getUser();
	},
	computed: {
		isLoggedIn() {
			return !!localStorage.getItem('sessionToken');
		},
	}
}
</script>

<template>
	<div :class="$utilities.isLoggedIn() ? 'app' : 'landing'">
		<navigation :userData="userData"/>
		<div>
			<router-view v-slot="{ Component }">
				<transition
						mode="out-in"
						name="fade-translate">
					<component
							:userData="userData"
							:is="Component"
							v-bind:class="{content: $utilities.isLoggedIn()}"
							@refreshUser="refreshUser"/>
				</transition>
			</router-view>
		</div>
		<footer-component/>
	</div>
</template>

<style scoped>
</style>