<script>
export default {
	props: ['account', 'hideDetails'],
	data() {
		return {}
	},
	methods: {},
	computed: {
	}
}
</script>

<template>
	<div class="card-cover"
		 :data-cover="account.cover">
		<img class="card-logo"
			 src="/assets/logos/logoblancopng.png"
			 alt="">

		<div class="card-info" v-if="!hideDetails">

			<p data-size="20"
			   data-weight="600">
				{{ account.name }}
			</p>

			<p data-size="13"
			   data-weight="400">
				Cuenta {{ account.accountType === 'corriente' ? 'corriente' : 'de ahorros'}}
			</p>
		</div>
	</div>
</template>

<style scoped>
</style>