<script>
export default {
	props: ['selected', 'accounts'],
	data() {
		return {
			searchBy: '',
			isOpen: false
		}
	},
	methods: {
		selectOption(option) {
			this.$emit('selectOption', option);
			this.searchBy = '';
			this.isOpen = false;
		},

	},
	computed: {
		listFiltered() {
			return this.accounts.filter(item => {
				let normalizeName = item.name.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase() + item.name.toLowerCase();
				return normalizeName.includes(this.searchBy.toLowerCase());
			})
		},
		selectedAccount() {
			if (this.selected)
				return this.accounts.find(item => item._id === this.selected);

			return {};
		}
	}
}
</script>

<template>
	<div>
		<div class="custom-select" v-on:mouseleave="isOpen = false">
			<div class="d-flex" data-align-items="center" data-gap-h="10">
				<card-cover class="w-50-px" data-round="5" v-if="selected" :account="selectedAccount" :hideDetails="true"></card-cover>
				<div class="w-50-px card-cover is-loading" data-round="5" v-else></div>

				<div class="preview grow mt-0" data-bg="blanco-puro" v-on:click="isOpen = !isOpen">

					<div v-if="selected" class="d-flex" data-align-items="center">
						{{ selectedAccount.name }}
					</div>
					<p v-else>Selecciona una cuenta</p>
					<i class="selector-icon ml-auto far fa-angle-down"></i>
				</div>
			</div>

			<div class="options" v-bind:class="{ show: isOpen }">
				<div class="option-container">
					<div class="input-group mt-0">
						<input type="text" v-model="searchBy" placeholder="Buscar o añadir">
					</div>

					<div class="separator-horizontal my-10"></div>

					<p data-weight="300" data-size="14" data-align-text="center" v-if="accounts.length === 0">¡No tienes más cuentas!</p>

					<div class="options-group">
						<div class="option-item"
							 v-bind:class="{selected: option._id === selected}"
							 v-for="option in listFiltered"
							 v-on:click="selectOption(option)">
							<div class="w-40-px mr-10">
								<card-cover data-round="5" :account="option" :hideDetails="true"></card-cover>
							</div>
							{{ option.name }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>