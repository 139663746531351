<script>
export default {
  props: [],
  data() {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<template>
  <div>
    <banner />
  </div>
</template>

<style scoped>
</style>
