export const API_URL = "https://apibnk.joseariza.dev";
// export const API_URL = "http://localhost:3005";

export const CUSTOM_CLASS_SWAL = {
	container: 'cs-container',
	popup: 'cs-popup',
	header: 'cs-header',
	title: 'cs-title',
	closeButton: 'cs-closeButton',
	icon: 'cs-icon',
	image: 'cs-image',
	htmlContainer: 'cs-htmlContainer',
	input: 'cs-input',
	inputLabel: 'cs-inputLabel',
	validationMessage: 'cs-validationMessage',
	actions: 'cs-actions',
	confirmButton: 'cs-confirmButton',
	denyButton: 'cs-denyButton',
	cancelButton: 'cs-cancelButton',
	loader: 'cs-loader',
	footer: 'cs-footer',
	timerProgressBar: 'cs-timerProgressBar',
}
export const CARD_COVERS = ['Líneas Vanguardistas', 'Geometría Ecléctica', 'Prismática Finura'];

export const LIMIT_CHARS_COMMENT = 150;

