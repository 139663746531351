<script>

export default {
	props: [],
	data() {
		return {
			coverCount: 3,
			accounts: [],
			accountSelected: 0,
			categorySelected: undefined,
			account: {
				name: '',
				accountType: 'corriente',
				cover: 0
			},
			transactions: [],
			windowData: {
				show: false,
				section: 0,
			},

			isLoading: false,
			isUpdating: false,
			errors: {},

			selectionData: {
				mode: 'mensual',
				range: {
					from: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
					to: moment().endOf('month').format('YYYY-MM-DD 23:59:59')
				}
			},

			showSuccessMessage: false,
			recentTransactionsNumber: 8,

		}
	},
	methods: {
		toggleWindowStatus() {
			this.windowData.show = !this.windowData.show;
			setTimeout(() => {
				if (!this.windowData.show)
					this.resetWindowStatus();
			}, 300)
		},
		getCategory(category) {
			const categoryFound = this.accountSelectedData.categories.find(item => item._id === category);
			return categoryFound ? categoryFound.name : '-';
		},
		resetWindowStatus() {
			this.account = {
				name: '',
				accountType: 'corriente',
				cover: 1,
			};

			this.isUpdating = false;
		},
		submitFormAccount() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {name} = this.account;
				this.errors = {};


				this.errors.name = !name.trim() ? 'El nombre de la cuenta es obligatorio' : undefined;


				if (!Object.values(this.errors).some(error => error !== undefined)) {
					axios.post(`${this.$storage.API_URL}/api/account/add`, this.account, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
						this.isLoading = false;

						this.toggleWindowStatus();
						this.resetWindowStatus();

						this.fetchAccounts();
					}).catch((er) => {
						console.log(er);
						this.errors = er.response.data.errors;
						this.isLoading = false;
					})
				} else
					this.isLoading = false;
			}
		},
		updateAccount() {
			if (!this.isLoading) {
				this.isLoading = true;

				const {name} = this.account;
				this.errors = {};

				this.errors.name = !name.trim() ? 'El nombre de la cuenta es obligatorio' : undefined;


				if (!Object.values(this.errors).some(error => error !== undefined)) {
					axios.put(`${this.$storage.API_URL}/api/account/${this.accountSelectedData._id}`, this.account, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
						this.isLoading = false;

						this.toggleWindowStatus();
						this.resetWindowStatus();

						this.fetchAccounts();
					}).catch((er) => {
						console.log(er);
						this.errors = er.response.data.errors;
						this.isLoading = false;
					})
				} else
					this.isLoading = false;
			}
		},
		fetchAccounts() {
			axios.post(`${this.$storage.API_URL}/api/account`, {}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
				this.accounts = res.data.accounts;

				this.fetchTransactions();
			})
		},
		changeSelectionInfo(option) {
			this.selectionData.mode = option;
		},

		validateName() {
			const maxLength = 15;
			if (this.account.name.length > maxLength) {
				this.account.name = this.account.name.slice(0, maxLength);
			} else {
				this.errors.name = '';
			}
		},

		changeAccount(value) {
			let isNext = value > 0;

			if (isNext && this.hasNext) this.accountSelected++;
			if (!isNext && this.hasPrev) this.accountSelected--;

			this.fetchTransactions()
		},

		openCreateAccount() {
			this.windowData.section = 0;
			this.toggleWindowStatus();
		},
		openEditAccount() {
			let {name, accountType, cover} = this.accountSelectedData;

			this.account = {name, accountType, cover};

			this.windowData.section = 1;

			setTimeout(() => {
				this.toggleWindowStatus();
			}, 200)
		},
		openLimits() {
			this.windowData.section = 2;

			setTimeout(() => {
				this.toggleWindowStatus();

			}, 200)
		},
		fetchTransactions() {
			if (this.accountSelectedData._id)
				axios.post(`${this.$storage.API_URL}/api/transaction/${this.accountSelectedData._id}`, {}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
					this.transactions = res.data.transactions;
				});
		},
		changeMonth(value) {
			let currentDate = moment(this.selectionData.range.from);

			currentDate = currentDate.add(value, "month");

			this.selectionData.range.from = currentDate.clone().startOf('month').format('YYYY-MM-DD 00:00:00');
			this.selectionData.range.to = currentDate.clone().endOf('month').format('YYYY-MM-DD 23:59:59');
		},
		deleteAccount() {
			Swal.fire({
				title: '¿Estás seguro?',
				icon: 'warning',
				text: 'Los movimientos serán eliminados y las transferencias de las otras cuentas serán convertidas en movimientos. Esta acción no se puede deshacer. ¿Estás seguro entonces?',
				showConfirmButton: true,
				confirmButtonText: 'Sí, lo estoy',
				showCancelButton: true,
				cancelButtonText: 'Mejor no',
				customClass: this.$storage.CUSTOM_CLASS_SWAL
			}).then(res => {
				if (res.isConfirmed)
					axios.delete(`${this.$storage.API_URL}/api/account/${this.accountSelectedData._id}`).then((res) => {
						this.toggleWindowStatus();
						this.accountSelected = 0;
						this.fetchAccounts();

					})
			})
		},
		editCategory(index) {
			this.categorySelected = {...this.accountSelectedData.categories[index]}
			this.windowData.section = undefined;
			setTimeout(() => {
				this.windowData.section = 3;
			}, 200)
		},
		goSelectionCategoryMenu() {
			this.categorySelected = undefined;
			this.windowData.section = undefined;
			setTimeout(() => {
				this.windowData.section = 2;
			}, 200)
		},
		updateCategory() {

			let {name, limit} = this.categorySelected;
			this.errors = {};

			if (limit === '') {
				limit = 0;
				this.categorySelected.limit = 0
			}

			this.errors.categoryName = !name.trim() ? 'El nombre de la categoría es obligatorio' : undefined;
			this.errors.categoryLimit = isNaN(limit) ? 'El límite de la cuenta debe ser un número' : undefined;


			if (!Object.values(this.errors).some(error => error !== undefined)) {
				limit = Math.abs(limit);
				axios.put(`${this.$storage.API_URL}/api/account/${this.accountSelectedData._id}/category/${this.categorySelected._id}`, {
					name,
					limit
				}, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
					this.fetchAccounts();
					this.showSuccessMessage = true;
					setInterval(() => {
						this.showSuccessMessage = false
					}, 2000)
				})
			}
		},
		deleteCategory(index) {
			Swal.fire({
				title: '¿Estás seguro?',
				icon: 'warning',
				html: 'Los movimientos bajo esta categoría de esta cuenta serán reemplazados por la categoría <span data-weight="600" data-color="azul">General</span>',
				showConfirmButton: true,
				confirmButtonText: 'Sí, lo estoy',
				showCancelButton: true,
				cancelButtonText: 'Mejor no',
				customClass: this.$storage.CUSTOM_CLASS_SWAL
			}).then(res => {
				if (res.isConfirmed) {
					this.categorySelected = {...this.accountSelectedData.categories[index]}

					axios.delete(`${this.$storage.API_URL}/api/account/${this.accountSelectedData._id}/category/${this.categorySelected._id}`, {headers: {'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`}}).then((res) => {
						this.windowData.section = undefined;
						setTimeout(() => {
							this.fetchAccounts();
							this.fetchTransactions();

							this.categorySelected = undefined;
							this.windowData.section = 2;
						})
					})
				}
			})
		}
	},
	computed: {
		userData() {
			return JSON.parse(localStorage.getItem('userData'));
		},
		isAccountSelected() {
			return !!this.accounts[this.accountSelected];
		},
		accountSelectedData() {
			return this.accounts[this.accountSelected] ?? {};
		},
		hasNext() {
			return this.accountSelected < (this.accounts.length - 1);
		},
		hasPrev() {
			return this.accountSelected !== 0;
		},
		lastTransactions() {
			return this.transactions.sort((a, b) => moment(b.dateSelected) - moment(a.dateSelected)).slice(0, this.recentTransactionsNumber);
		},
		transactionsFiltered() {
			let init = moment(this.selectionData.range.from);
			let end = moment(this.selectionData.range.to);

			let transactions = this.transactions.filter(item => {
				let dateSelected = moment(item.dateSelected);
				return dateSelected.isBetween(init, end, null, '[]');
			});

			transactions = transactions.map(item => {
				return {...item, dateSelectedParsed: new Date(item.dateSelected).getTime()}
			});

			return transactions;
		},
		earnings() {

			return this.transactionsFiltered.reduce((acc, transaction) => {
				return transaction.amount > 0 ? acc + transaction.amount : acc;
			}, 0);
		},
		lost() {
			return this.transactionsFiltered.reduce((acc, transaction) => {
				return transaction.amount < 0 ? acc + transaction.amount : acc;
			}, 0);
		},
	},
	mounted() {
		this.fetchAccounts();
	}
}
</script>

<template>
	<div class="content" id="accounts-content">
		<div class="window-container" v-bind:class="{opened: windowData.show}">
			<div class="background" v-on:click="toggleWindowStatus"></div>
			<div class="window">
				<div class="window-content" v-bind:class="{show: windowData.section === 1 || windowData.section === 0}">
					<div v-if="windowData.section === 0">
						<div class="d-flex"
							 data-align-items="center">
							<h3 data-color="azul-oscuro">Crea una nueva <span data-color="naranja">cuenta</span></h3>

							<i v-on:click="toggleWindowStatus" data-opacity="2" class="pointer ml-auto fas fa-xmark"/>
						</div>

						<p data-weight="300" data-size="14" class="mt-20">Crea tu cuenta bancaria rellenando este
							sencillo
							formulario y comienza a organizarte mejor</p>
					</div>
					<div v-else>
						<div class="d-flex" data-align-items="center">
							<h3 data-color="azul-oscuro">Modifica
								"<span data-color="naranja">{{ accountSelectedData.name }}</span>"
							</h3>

							<i v-on:click="toggleWindowStatus" data-opacity="2" class=" ml-auto fas fa-xmark"/>
						</div>

						<p data-weight="300" data-size="14" class="mt-20">Crea tu cuenta bancaria rellenando este
							sencillo
							formulario y comienza a organizarte mejor</p>
					</div>
					<div class="separator-horizontal my-30"></div>
					<div class="form-group" v-bind:class="{'has-error': errors.name}">
						<label for="name">Nombre de la cuenta</label>
						<div class="input-group">
							<input id="name"
								   type="text"
								   v-model="account.name"
								   v-on:input="validateName"
								   v-on:focus="delete errors.name">
						</div>
					</div>
					<div class="d-grid" data-columns="2" data-gap-h="20">
						<div class="form-group mt-30">
							<label for="name">Tipo de cuenta</label>
							<div class="input-group">
								<select id="accountType" v-model="account.accountType">
									<option value="corriente">Cuenta corriente</option>
									<option value="ahorros">Cuenta de ahorros</option>
								</select>
							</div>
						</div>

						<div class="form-group mt-30">
							<label for="name">Portada</label>
							<div class="input-group">
								<select id="accountType" v-model="account.cover">
									<option v-for="(cover, key) in $storage.CARD_COVERS" :value="key">
										{{ cover }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<card-cover class="my-50"
								:account="account"></card-cover>
					<button v-if="windowData.section === 0" type="button"
							v-on:click="submitFormAccount"
							:disabled="isLoading"
							class="w-100 button"
							data-bg="azul"
							data-mode="borde"
							data-behaviour="hover"
							data-hover-mode="translucido">
						{{ isLoading ? 'Un segundo...' : '¡Crear cuenta nueva!' }}
					</button>
					<div v-else>
						<button type="button"
								v-on:click="updateAccount"
								:disabled="isLoading"
								class="w-100 button"
								data-bg="azul"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">
							{{ isLoading ? 'Un segundo...' : '¡Actualizar!' }}
						</button>

						<button type="button"
								v-on:click="deleteAccount"
								:disabled="isLoading"
								class="w-100 button mt-10"
								data-bg="naranja"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">
							{{ isLoading ? 'Un segundo...' : 'Quiero eliminarla' }}
						</button>
					</div>
				</div>
				<div class="window-content" v-bind:class="{show: windowData.section === 2}">
					<h3 data-color="azul-oscuro">Gestiona tus <span data-color="naranja">categorías</span></h3>

					<p data-weight="300" data-size="14" class="mt-20">Crea, edita, elimina y añade límites a cada una de
						las categorías de esta cuenta.</p>
					<div class="scroll-zone d-flex column mt-15">
						<div class="d-flex category-item"
							 data-round="10"
							 data-align-items="center"
							 data-gap-h="10"
							 v-for="(category, index) in accountSelectedData.categories">
							<div class="square centered w-20-px uppercase" data-size="10" data-round="8"
								 data-bg="morado" data-mode="translucido">{{ category.name.charAt(0) }}
							</div>
							<p class="first-uppercase" data-weight="300">{{ category.name }}</p>
							<p class="ml-auto pointer" v-on:click="editCategory(index)" data-size="14"
							   data-color="morado"><i class="fad fa-gear"></i>
							</p>

							<p class="ml-5 pointer" v-if="category.canDelete" v-on:click="deleteCategory(index)"
							   data-size="14" data-color="naranja"><i
									class="fad fa-trash"></i></p>
							<p class="ml-5 pointer" v-else data-size="14" data-color="gris"><i class="fad fa-trash"></i>
							</p>
						</div>
					</div>
				</div>

				<div class="window-content" v-bind:class="{show: windowData.section === 3}">


					<div v-if="categorySelected !== undefined">
						<div class="d-flex" data-align-items="center">

							<h3 data-color="azul-oscuro" class="d-flex" data-align-items="center">
								<i class="fad fa-arrow-left mr-10"
								   data-color="naranja"
								   v-on:click="goSelectionCategoryMenu"/>
								Editar <span class="ml-5" data-color="naranja">categoría</span>
							</h3>
							<div class="d-flex py-5 px-10 ml-auto" :data-opacity="showSuccessMessage ? 10 : 0"
								 data-round="15" data-gap-h="15"
								 data-align-items="center"
								 data-bg="verde-warning">
								<i data-color="verde" class="fad fa-circle-check"></i>

								<p data-size="11" data-weight="300">Guardado</p>
							</div>
						</div>

						<div class="form-group mt-20" v-if="categorySelected.canDelete"
							 v-bind:class="{'has-error': errors.categoryName}">
							<label for="">Nombre</label>
							<div class="input-group">
								<input type="text" v-on:focus="delete errors.categoryName"
									   v-model="categorySelected.name">
							</div>
						</div>

						<div class="form-group mt-20" data-opacity="7" v-else>
							<label for="">Nombre</label>
							<div class="input-group" data-bg="blanco-sucio">
								<input type="text" disabled :value="categorySelected.name">
							</div>
						</div>

						<div class="form-group mt-10" v-bind:class="{'has-error': errors.categoryLimit}">
							<label for="">Límite</label>
							<div class="input-group">
								<input type="text" v-on:focus="delete errors.categoryLimit"
									   v-model="categorySelected.limit">
							</div>
						</div>


						<button type="button"
								v-on:click="updateCategory"
								class="button w-100 mt-30"
								data-bg="azul"
								data-mode="borde"
								data-behaviour="hover"
								data-hover-mode="translucido">
							¡Actualizar categoría!
						</button>
					</div>

				</div>
			</div>
		</div>

		<!-- Columna izquierda -->
		<div class="d-flex column" data-gap-v="30">
			<div class="card"
				 data-bg="blanco-puro">
				<!-- Cuenta información -->
				<div class="d-flex" data-align-items="center">
					<!-- Nombre y selector de cuenta -->
					<div class="d-flex" data-align-items="center">
						<p data-weight="700"
						   data-size="20">
							Mis cuentas
						</p>
						<div v-if="accounts.length > 1"
							 class="d-flex ml-20"
							 data-align-items="center"
							 data-gap-h="5">
							<div class="square w-20-px h-20-px d-flex pointer" :data-opacity="hasPrev ? 10 : 2"
								 data-align-items="center"
								 data-justify-content="center"
								 data-bg="blanco-sucio"
								 data-mode="borde"
								 data-round="8" v-on:click="changeAccount(-1)">
								<i class="far fa-angle-left"
								   data-color="azul-oscuro"/>
							</div>
							<p class="mx-10" data-size="11" data-weight="300">{{ accountSelected + 1 }} de
								{{ accounts.length }}</p>
							<div class="square w-20-px h-20-px d-flex pointer" :data-opacity="hasNext ? 10 : 2"
								 data-align-items="center"
								 data-justify-content="center"
								 data-bg="blanco-sucio"
								 data-mode="borde"
								 data-round="8" v-on:click="changeAccount(1)">
								<i class="far fa-angle-right"
								   data-color="azul-oscuro"/>
							</div>
						</div>
					</div>

					<!-- Botón añadir cuenta -->
					<div class="d-flex ml-auto pointer"
						 v-on:click="openCreateAccount"
						 data-align-items="center"
						 data-gap-h="10">
						<div class="square w-16-px h-16-px d-flex"
							 data-align-items="center"
							 data-justify-content="center"
							 data-bg="azul"
							 data-mode="borde"
							 data-round="5">
							<i class="fas fa-plus"
							   data-size="10"/>
						</div>
						<p id="addAccountText" data-color="azul" data-size="14">Añadir cuenta</p>
					</div>
				</div>

				<!-- Cuenta imagen -->
				<div class="account-info mt-30">
					<card-cover v-if="isAccountSelected"
								:account="accountSelectedData"></card-cover>

					<div v-else class="card-cover is-loading" data-round="10"></div>

					<div v-if="isAccountSelected"
						 class="account-actions">
						<!-- Movimientos -->
						<router-link :to="{name: 'transactions', params: {accountId: accountSelectedData._id}}"
									 class="d-flex column pointer"
									 data-round="20"
									 data-align-items="center"
									 data-justify-content="center"
									 data-bg="blanco-sucio"
									 data-mode="borde">
							<i data-color="naranja"
							   data-size="20"
							   class="fal fa-nfc-signal"/>
							<p data-size="14"
							   class="mt-10">
								Movimientos
							</p>
						</router-link>

						<!-- Editar cuenta -->
						<div class="d-flex column pointer"
							 data-round="20"
							 data-align-items="center"
							 data-justify-content="center"
							 data-bg="blanco-sucio"
							 data-mode="borde"
							 v-on:click="openEditAccount">
							<i data-color="azul"
							   data-size="20"
							   class="fal fa-marker"/>
							<p data-size="14"
							   class="mt-10">
								Cambiar detalles
							</p>
						</div>
					</div>

					<!-- Está cargando -->
					<div v-else class="account-actions">
						<!-- Movimientos -->
						<div class="d-flex column pointer is-loading"
							 data-round="20">
						</div>

						<!-- Editar cuenta -->
						<div class="d-flex column pointer is-loading"
							 data-round="20">

						</div>
					</div>
				</div>
			</div>

			<div class="card"
				 id="transactionCard"
				 data-bg="transparente">
				<div class="d-flex mb-30"
					 data-align-items="center">
					<div class="d-flex"
						 data-align-items="center">
						<p data-weight="700"
						   data-size="20">
							Movimientos recientes
						</p>
					</div>
				</div>

				<div v-if="isAccountSelected && lastTransactions.length > 0">

					<div v-for="transaction in lastTransactions">
						<div class="transaction mb-10">
							<div class="d-flex transaction-icon"
								 data-align-items="center"
								 data-justify-content="center"
								 data-bg="naranja"
								 data-round="20"
								 data-mode="translucido-light">
								<span data-size="24" data-weight="300">{{
										transaction.concept.charAt(0).toUpperCase()
									}}</span>
							</div>

							<div class="d-flex column"
								 data-justify-content="center">
								<p class="first-uppercase transactionDate"
								   data-opacity="4"
								   data-size="12"
								   data-weight="300">
									{{ getCategory(transaction.category) }} · {{
										$utilities.capitalizeString($utilities.getTextDate(transaction.dateSelected))
									}}</p>
								<p data-size="18" class="transactionConcept"
								   data-weight="600">{{ transaction.concept }}</p>
							</div>

							<div class="d-flex column"
								 data-align-items="flex-end"
								 data-justify-content="center">
								<p data-size="18"
								   class="transactionAmount"
								   :data-color="transaction.amount <0 ? 'naranja' : 'azul'"
								   data-weight="700"
								   data-family="montserrat">
									{{ $utilities.formatNumber(transaction.amount, 2, 2) }}€</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="transaction mb-10" v-for="i in recentTransactionsNumber">
						<div class="d-flex is-loading"
							 data-round="20">
						</div>

						<div class="d-flex column" data-justify-content="center">
							<div class="mb-5 h-12-px w-50-px is-loading"></div>
							<div class="mb-5 h-18-px w-80 is-loading"></div>
							<div class="mb-5 h-12-px w-50-px is-loading"></div>
						</div>

						<div class="d-flex column"
							 data-align-items="flex-end"
							 data-justify-content="center">
							<div class="mb-5 h-18-px w-50 is-loading"></div>


						</div>
					</div>
				</div>

			</div>
		</div>

		<!-- Columna derecha -->
		<div class="rightColumn d-flex column"
			 data-gap-v="30">


			<div class="card" data-bg="blanco-puro">
				<div id="apps" v-if="isAccountSelected">
					<router-link :to="{name: 'debts', params: {accountId: accountSelectedData._id}}" class="appItem">
						<div class="icon w-100" data-bg="blanco" data-mode="borde">
							<i data-color="azul" class="fad fa-receipt"></i>
						</div>
						<p data-size="14" data-weight="300" data-align-text="center">Gestionar<br>deudas</p>
					</router-link>

					<div class="appItem">
						<div class="icon w-100" data-bg="blanco" data-mode="borde">
							<i data-color="verde" class="fad fa-calendar-alt"></i>
						</div>
						<p data-size="14" data-weight="300" data-align-text="center">Pagos<br>automáticos</p>
					</div>

					<div class="appItem">
						<div class="icon w-100" data-bg="blanco" data-mode="borde">
							<i data-color="morado" class="fad fa-file-alt"></i>
						</div>
						<p data-size="14" data-weight="300" data-align-text="center">Gestionar<br>nómina</p>
					</div>
				</div>
			</div>

			<!-- Gráfica -->
			<div class="card"
				 data-bg="blanco-puro">
				<div v-if="isAccountSelected" class="d-flex mb-30" data-align-items="center">
					<div class="form-group d-none">
						<custom-selection :selected="selectionData.mode"
										  @selectOption="changeSelectionInfo"
										  :options="[{title: 'mensual', value: 'mensual'}]"/>
					</div>

					<div class="d-flex w-100" data-gap-h="5" data-align-items="center">

						<p class="mr-auto"
						   data-color="naranja"
						   data-weight="500"
						   data-size="14"><i class="far fa-calendar-alt mr-10"></i><span>{{
								$utilities.capitalizeString($utilities.formatDate(selectionData.range.from, {format: 'MMMM YYYY'}))
							}}</span></p>

						<button data-round="8" class="pointer px-10 py-5 ml-10" v-on:click="changeMonth(-1)"
								data-bg="naranja" data-mode="borde" data-behaviour="hover"
								data-hover-mode="translucido"><i
								class="fas fa-angle-left"></i></button>
						<button data-round="8" class="pointer px-10 py-5" v-on:click="changeMonth(1)" data-bg="naranja"
								data-mode="borde" data-behaviour="hover" data-hover-mode="translucido"><i
								class="fas fa-angle-right"></i></button>

					</div>
				</div>
				<div v-else class="d-flex mb-30" data-align-items="center">
					<div class="h-20-px w-120-px is-loading"></div>

					<div class="ml-auto h-14-px w-120-px is-loading"></div>

				</div>

				<graph-transactions-resume v-if="isAccountSelected" :transactions="transactionsFiltered"
										   :range="selectionData.range"/>
				<div v-else class="h-300-px is-loading"></div>

				<div class="separator-horizontal my-30"></div>

				<div v-if="isAccountSelected" class="statistics d-flex" data-align-items="center">
					<div class="statisticsContainer d-flex w-100"
						 data-align-items="center"
						 data-gap-h="20">
						<div class="w-70-px h-70-px d-flex mr-auto"
							 data-align-items="center"
							 data-justify-content="center"
							 data-bg="blanco-sucio"
							 data-round="30"
							 data-mode="borde">
							<i data-size="30"
							   data-color="azul"
							   class="fa-thin fa-arrow-trend-up"/>
						</div>

						<div>
							<p data-opacity="4"
							   data-align-text="right"
							   data-size="14">Ganancias</p>
							<p data-size="25"
							   data-align-text="right"
							   data-weight="700"
							   data-color="azul"
							   data-family="montserrat">{{ $utilities.formatNumber(earnings, 2, 2) }}€</p>
						</div>

					</div>
					<div class="statisticsSeparator mx-30 h-100">
						<div class="separator-vertical"></div>
					</div>
					<div class="statisticsContainer d-flex w-100"
						 data-align-items="center"
						 data-gap-h="20">
						<div class="w-70-px h-70-px d-flex mr-auto"
							 data-align-items="center"
							 data-justify-content="center"
							 data-bg="blanco-sucio"
							 data-round="30"
							 data-mode="borde">
							<i data-size="30"
							   data-color="amarillo-pollo"
							   class="fa-thin fa-arrow-trend-down"/>
						</div>

						<div>
							<p data-opacity="4"
							   data-align-text="right"
							   data-size="14">Gastos</p>
							<p data-size="25"
							   data-align-text="right"
							   data-weight="700"
							   data-color="amarillo-pollo"
							   data-family="montserrat">{{ $utilities.formatNumber(lost, 2, 2) }}€</p>
						</div>

					</div>

				</div>

				<div v-else class="d-flex" data-align-items="center">
					<div class="d-flex"
						 data-align-items="center"
						 data-gap-h="20">
						<div class="w-70-px h-70-px is-loading"
							 data-round="30">
						</div>

						<div>
							<div class="h-14-px w-70-px is-loading"></div>
							<div class="h-25-px w-120-px is-loading mt-10"></div>
						</div>

					</div>
					<div class="mx-auto h-100">
						<div class="separator-vertical"></div>
					</div>
					<div class="d-flex"
						 data-align-items="center"
						 data-gap-h="20">
						<div class="w-70-px h-70-px is-loading" data-round="30">
						</div>

						<div>
							<div class="h-14-px w-70-px is-loading"></div>
							<div class="h-25-px w-120-px is-loading mt-10"></div>
						</div>

					</div>

				</div>
			</div>

			<div class="card"
				 data-bg="blanco-puro">
				<div class="d-flex"
					 data-align-items="center">
					<div class="d-flex column" data-gap-v="15" v-if="isAccountSelected">

						<div class="d-flex" data-align-items="center">
							<p data-weight="700"
							   data-size="20">
								Gasto por categorías
							</p>

							<div class="d-flex ml-auto pointer" data-align-items="center" v-on:click="openLimits">
								<div class="h-20-px d-flex w-20-px mr-10" data-round="8" data-align-items="center"
									 data-justify-content="center" data-bg="naranja" data->
									<i data-size="10" class="fad fa-gear"></i>
								</div>
								<p id="manageCategoriesText" data-size="14" data-color="naranja">Gestionar
									categorías</p>
							</div>
						</div>

						<p class="mt-5" data-size="14" data-weight="300">Controla por categorías en qué te gastas el
							dinero. Estos
							números corresponden al cómputo de <span data-weight="600" data-color="azul">gastos</span> e
							<span data-weight="600" data-color="azul">ingresos</span>.</p>
					</div>
					<div v-else class="h-20-px is-loading w-100-px">
					</div>
				</div>

				<div class="mt-30" v-if="isAccountSelected">
					<category-limit v-for="category in accountSelectedData.categories"
									v-bind:key="category"
									:category="category"
									:transactions="transactionsFiltered"></category-limit>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>